const getTypeNotification = (type) => {
    switch (type) {
        case 'validation-request-eden':
            return 'Memorial Validation Request';
        case 'validation-request-ark':
            return 'Legacy Validation Request';
        case 'report':
            return 'Report';
        case 'ticket':
            return 'Costumer support';
        case 'interested-user':
            return 'Interested user';
        case 'B2B-service':
            return 'B2B service request';
        case 'establishment-payment':
            return 'Establishment payment';
        case 'refferal-payment':
            return 'Refferal payment';
        default:
            return 'Notification';
    }
};

export default getTypeNotification;
