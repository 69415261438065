import PropTypes from 'prop-types';

import useGetUserAccess from 'hooks/useGetUserAccess';
import Loader from 'ui-component/Loader';
import Error401 from 'views/pages/maintenance/Error401';

const hasPermission = ({ permissions, scopes, isGroup }) => {
    if (permissions && scopes.length) {
        const scopesMap = {};
        scopes.forEach((scope) => {
            scopesMap[`webadmin.${scope}`] = true;
        });

        return permissions.some((permission) =>
            scopes.find((scope) => {
                const permissionName = permission.name.replace('webadmin.', '');
                if (isGroup) return permissionName.includes(scope);
                return permissionName === scope;
            })
        );
    }
    return false;
};

export default function PermissionGuard({ children, scopes = [], isNavBar = true, isGroup }) {
    const { permissions } = useGetUserAccess();

    if (permissions) {
        const permissionGranted = hasPermission({ permissions, scopes, isGroup });

        if (!permissionGranted && isNavBar) return <></>;

        if (!permissionGranted && !isNavBar) return <Error401 />;

        return <>{children}</>;
    }
    return <Loader />;
}

PermissionGuard.propTypes = {
    children: PropTypes.node,
    scopes: PropTypes.array,
    isNavBar: PropTypes.bool,
    isGroup: PropTypes.bool
};
