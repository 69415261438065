const AdminIcon = (props) => (
    <svg width={54} height={54} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g filter="url(#a)">
            <circle cx={27} cy={23} r={19} fill="#fff" />
            <path
                d="M27 25.5c-3.337 0-10 1.675-10 5V33h20v-2.5c0-3.325-6.663-5-10-5Zm-7.5 5c.275-.9 4.137-2.5 7.5-2.5 3.375 0 7.25 1.613 7.5 2.5h-15ZM27 23c2.762 0 5-2.238 5-5s-2.238-5-5-5-5 2.238-5 5 2.238 5 5 5Zm0-7.5c1.375 0 2.5 1.125 2.5 2.5s-1.125 2.5-2.5 2.5a2.507 2.507 0 0 1-2.5-2.5c0-1.375 1.125-2.5 2.5-2.5Z"
                fill="#335CD4"
            />
        </g>
        <defs>
            <filter id="a" x={0} y={0} width={54} height={54} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={4} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4973_7098" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_4973_7098" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default AdminIcon;
