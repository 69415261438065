import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'roles';

export class RolesService extends AxiosRepository {
    async getRoles() {
        return super.getWithClient(RESOURCE);
    }

    async getRolesAndPermissions() {
        return super.getWithClient(`${RESOURCE}/permissions`);
    }

    async getRoleInformation(id) {
        return super.getWithClient(`${RESOURCE}/info/${id}`);
    }

    async createRole(payload) {
        return super.postWithClient(payload, RESOURCE);
    }

    async deleteRole(id) {
        return super.delete(id, RESOURCE);
    }
}

export const rolesHttpService = new RolesService();
