/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';
import { swagginHttpService } from 'api/swaggin.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    reviews: [],
    page: 0,
    totalRates: 0,
    counterReviews: {}
};

const slice = createSlice({
    name: 'reviews',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET REVIEWS
        getReviews(state, action) {
            state.reviews = action.payload;
        },

        // GET TOTAL COUNTER REVIEWS
        getCounterReviews(state, action) {
            state.counterReviews = action.payload;
        },

        setTotalRates(state, action) {
            state.totalRates = action.payload;
        },

        setPage(state, action) {
            state.page = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getReviews(commerceId, rate, skip, limit) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await swagginHttpService.getReviews(commerceId, rate, skip, limit);
            const {
                result: { rates, totalRates, page }
            } = response;
            dispatch(slice.actions.setTotalRates(totalRates));
            dispatch(slice.actions.getReviews(rates));
            dispatch(slice.actions.setPage(page));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

const parseData = (array) => {
    const temp = {};
    array.forEach((item) => {
        temp[item._id] = item.count;
    });
    return temp;
};

export function getCounterReviews() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await swagginHttpService.getCounterReviews();
            const { result } = response;
            dispatch(slice.actions.getCounterReviews(parseData(result)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function refreshPage() {
    return () => dispatch(slice.actions.setPage(0));
}
