const AffiliatesIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} fill="none" {...props}>
        <g fill="#335CD4" clipPath="url(#a)">
            <path d="M12.26 6.657c-.522-.195-.997.181-1.008.8-.057 3.135-2.323 5.846-5.412 6.49-.896.186-1.8.132-2.703.137-1.44.007-2.387.941-2.388 2.37v6.536h4.48v-.32l.003-6.338c0-.548.21-.8.75-.902 2.05-.391 3.713-1.422 4.997-3.046a8.019 8.019 0 0 0 1.767-4.968c.005-.37-.175-.642-.487-.759Zm9.157 7.482c-.579-.079-1.174-.048-1.761-.052-2.406-.017-4.043-.964-5.617-2.81-.387-.474-1.06-1.32-1.23-1.659 0 0 .02.599-.686 1.704 0 0 .546.712 1.125 1.357 1.247 1.426 2.807 2.362 4.718 2.742.605.12.802.344.802.958l.002 6.336v.27h4.46c.009-.032.017-.047.017-.062 0-2.244.015-4.487-.007-6.73-.01-1.015-.808-1.915-1.823-2.053v-.001Zm-.893-1.556s.693.068 1.091-.019c1.688-.411 2.466-1.879 2.379-3.148-.099-1.43-1.252-2.603-2.709-2.75-1.415-.142-2.797.817-3.156 2.189a2.88 2.88 0 0 0 .385 2.392c.474.728 1.163 1.148 2.01 1.336Z" />
            <path d="M1.373 12.125a2.91 2.91 0 0 0 1.704.436l.053-.003a2.933 2.933 0 0 0 1.271-.359c1.12-.622 1.716-1.68 1.562-2.882-.19-1.479-1.386-2.605-2.823-2.657C1.647 6.605.344 7.613.06 9.048a2.861 2.861 0 0 0 .33 2.031c.255.448.586.794.983 1.046Zm9.882-8.953c.004.458.32.789.745.789.426 0 .742-.33.747-.788.004-.396-.02-.792.005-1.186.03-.461-.122-.799-.552-.996h-.398c-.43.197-.583.535-.553.996.025.394.002.79.006 1.186v-.001ZM8.533 5.263c.228.217.52.244.81.102.274-.133.394-.364.426-.726-.039-.092-.067-.274-.172-.384a41.506 41.506 0 0 0-1.616-1.611c-.284-.265-.726-.216-.992.055-.267.271-.306.705-.037.988.512.54 1.042 1.064 1.581 1.576Zm6.536.192c.09-.042.277-.08.393-.19a42.27 42.27 0 0 0 1.584-1.572c.277-.292.235-.737-.046-1.007-.275-.264-.71-.298-.995-.03a44.692 44.692 0 0 0-1.583 1.574c-.21.22-.238.503-.108.785.131.283.368.412.754.44Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path fill="#fff" d="M0 .99h24v22H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default AffiliatesIcon;
