/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { beSoulHttpService } from 'api/besoul.service';
import getPlanName from 'utils/functions/getPlanName';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    usersOfToday: [],
    allUsers: [],
    goal: 10,
    page: 0,
    totalAllUsers: 0,
    usersOfTodayNumber: null
};

const slice = createSlice({
    name: 'besoulUsers',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // IS LOADING
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET USERS OF TODAY
        getUsersOfToday(state, action) {
            state.usersOfToday = action.payload;
        },

        // GET ALL USERS
        getAllUsers(state, action) {
            state.allUsers = action.payload;
        },

        // SET USERS OF TODAY NUMBER
        setUsersOfTodayNumber(state, action) {
            state.usersOfTodayNumber = action.payload;
        },

        // SET PAGE
        setPage(state, action) {
            state.page = action.payload;
        },

        // SET TOTAL USERS CREATED
        setTotalUsers(state, action) {
            state.totalAllUsers = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

const parseData = (data) => {
    const outputArray = [];
    try {
        for (const value of data) {
            outputArray.push({
                id: value._id,
                fullName: `${value.name} ${value.lastName}`,
                email: value.email ? value.email : 'N/A',
                cellphone: value.phone ? `+${value.phone.code} ${value.phone.number}` : 'N/A',
                promoCode: value.promoCode || 'N/A',
                plan: value.productId ? getPlanName(value.productId) : 'N/A',
                creationDate: value.createdAt
            });
        }
    } catch (error) {
        console.erorr('error', error);
    }
    return outputArray;
};

export function getUsersOfTodayData() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = (await beSoulHttpService.getUsersOfToday()).data.users;
            dispatch(slice.actions.setUsersOfTodayNumber(response.length || 0));
            dispatch(slice.actions.getUsersOfToday(parseData(response)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
export function getAllUsersData(skip, limit) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = (await beSoulHttpService.getAllUsers(skip, limit)).data;
            // dispatch(slice.actions.setPage(response.page));
            dispatch(slice.actions.setTotalUsers(response.total));
            dispatch(slice.actions.getAllUsers(parseData(response.users)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function refreshPage() {
    return () => dispatch(slice.actions.setPage(0));
}
