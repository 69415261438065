/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { Chip, CircularProgress, Grid } from '@mui/material';

// project imports
import { notificationsHttpService } from 'api/notification.service';

const NotificationAction = ({ theme, notification }) => {
    const [notificationSelected, setnotificationSelected] = useState(notification);
    const [loading, setLoading] = useState(false);

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: '#FFF',
        backgroundColor: theme.palette.grey[500]
    };

    const chipAsReadSX = {
        ...chipSX,
        color: '#FFF',
        backgroundColor: theme.palette.primary.dark,
        cursor: 'pointer',
        ':hover': {
            backgroundColor: theme.palette.primary.main
        }
    };

    const handleChipClick = async (event) => {
        event.stopPropagation(); // Detiene la propagación del evento

        setLoading(true);
        await notificationsHttpService
            .updateNotifications([
                {
                    id: notification._id
                }
            ])
            .then(() =>
                setnotificationSelected({
                    ...notificationSelected,
                    isRead: true
                })
            )
            .catch((err) => console.error('error', err))
            .finally(() => setLoading(false));
    };

    return (
        <>
            {loading ? (
                <CircularProgress size={28} color="inherit" />
            ) : (
                <Grid item>
                    {notificationSelected.isRead ? (
                        <Chip label="Read" sx={chipWarningSX} />
                    ) : (
                        <Chip label="Mark as read" onClick={handleChipClick} sx={chipAsReadSX} />
                    )}
                </Grid>
            )}
        </>
    );
};

NotificationAction.propTypes = {
    theme: PropTypes.object.isRequired,
    notification: PropTypes.object.isRequired
};

export default NotificationAction;
