import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'ear';

export class EarService extends AxiosRepository {
    async getCustomers() {
        return super.getWithClient(`${RESOURCE}/customers`);
    }

    async updateCustomer(payload) {
        return super.putWithClient(payload, `${RESOURCE}/customers`);
    }

    async createCustomer(payload) {
        return super.postWithClient(payload, `${RESOURCE}/customers`);
    }

    async getTicket(id) {
        return super.getByIdWithClient(id, `${RESOURCE}/tickets`);
    }

    async changeStatus(payload) {
        return super.putWithClient(payload, `${RESOURCE}/tickets`);
    }

    async getChats(id) {
        return super.getByIdWithClient(id, `${RESOURCE}/chats`);
    }

    async sendMessage(payload) {
        return super.postWithClient(payload, `${RESOURCE}/chats/v2`);
    }
}

export const earHttpService = new EarService();
