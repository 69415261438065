// third-party
import { FormattedMessage } from 'react-intl';

// project-imports
import { SCOPES } from 'permissions';

// assets
import Eye from '@mui/icons-material/RemoveRedEye';

// constant
const icons = {
    Eye
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const biometric = {
    id: 'biometric-module',
    type: 'group',
    scope: SCOPES.biometric,
    children: [
        {
            id: 'biometric-module',
            title: 'Biometric module',
            type: 'collapse',
            url: '/biometric',
            icon: icons.Eye,
            level: 0,
            children: [
                {
                    id: 'transactions',
                    title: <FormattedMessage id="bio-transactions" />,
                    type: 'item',
                    icon: icons.Eye,
                    url: '/Biometric',
                    scope: SCOPES.transactions,
                    level: 1
                }
            ]
        }
    ]
};
export default biometric;
