// third-party
import { createSlice } from '@reduxjs/toolkit';
import { earHttpService } from 'api/ear.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    chats: []
};

const slice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET USER CHATS
        getTicketsChatsSuccess(state, action) {
            state.chats = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTicketChats(ticketId) {
    return async () => {
        try {
            const response = await earHttpService.getChats(ticketId);
            dispatch(slice.actions.getTicketsChatsSuccess(response.result.messages));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function insertChat(chat) {
    return async () => {
        try {
            await earHttpService.sendMessage(chat);
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
