import { APP_CHECK_DEBUG_TOKEN } from 'config';

/* eslint-disable no-restricted-globals */
const activateDebugTokenIfApply = () => {
    if (location.hostname === 'localhost') {
        self.FIREBASE_APPCHECK_DEBUG_TOKEN = APP_CHECK_DEBUG_TOKEN;
        return true;
    }

    return null;
};

export default activateDebugTokenIfApply;
