import { BACKEND_HOST } from 'config';
import createAxiosClient from './createAxiosClient';

const REFRESH_TOKEN_URL = 'http://localhost:5000/api/v1/auth/refreshToken';
const BASE_URL = BACKEND_HOST;

function getCurrentAccessToken() {
    const userKey = Object.keys(window.sessionStorage).filter((item) => item.startsWith('firebase:authUser'))[0];
    const authUser = userKey ? JSON.parse(sessionStorage.getItem(userKey)) : undefined;
    return authUser.stsTokenManager.accessToken;
}

function getAppValidationToken() {
    const appCheckToken = sessionStorage.getItem('firebase:appCheck');
    return appCheckToken;
}

function getCurrentRefreshToken() {
    const userKey = Object.keys(window.sessionStorage).filter((item) => item.startsWith('firebase:authUser'))[0];
    const authUser = userKey ? JSON.parse(sessionStorage.getItem(userKey)) : undefined;
    return authUser.stsTokenManager.refreshToken;
}

function setRefreshedTokens() {
    // this function receive the tokens
    console.log('set tokens...');
}

async function logout() {
    console.log('logout...');
}

const client = createAxiosClient({
    options: {
        baseURL: BASE_URL,
        timeout: 300000,
        headers: {
            'Content-Type': 'application/json'
        }
    },
    getCurrentAccessToken,
    getCurrentRefreshToken,
    getAppValidationToken,
    refreshTokenUrl: REFRESH_TOKEN_URL,
    logout,
    setRefreshedTokens
});

export default client;
