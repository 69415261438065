import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'biometric/transactions';
const DOCUMENT_RESOURCE = 'biometric/documents';
const FACE_RESOURCE = 'biometric/faces';
const INVITE_RESOURCE = 'biometric/invitation';
const VALIDATE_RESOURCE = 'biometric/validation';

export class BiometricService extends AxiosRepository {
    async getBiometricUsers(skip, limit, filter, search) {
        if (search) {
            return super.getWithClient(`${RESOURCE}?skip=${skip}&limit=${limit}&${filter}=${search}`);
        }
        return super.getWithClient(`${RESOURCE}?skip=${skip}&limit=${limit}`);
    }

    async getBiometricDocuments(documentId) {
        return super.getWithClient(`${DOCUMENT_RESOURCE}/${documentId}`);
    }

    async getBiometricFaces(faceId) {
        return super.getWithClient(`${FACE_RESOURCE}/${faceId}`);
    }

    async postBiometricInvite(body) {
        return super.postWithClient(body, INVITE_RESOURCE);
    }

    async postBiometricValidate(body) {
        return super.postWithClient(body, VALIDATE_RESOURCE);
    }
}

export const biometricHttpService = new BiometricService();
