// third-party
import { FormattedMessage } from 'react-intl';

// assets
import SwagginIcon from '../assets/logos/SwagginIcon';
import { IconLayoutNavbar, IconHeadset, IconTool, IconSwitchHorizontal } from '@tabler/icons';
import StarIcon from '@mui/icons-material/Star';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import StorefrontIcon from '@mui/icons-material/Storefront';
import EmailIcon from '@mui/icons-material/Email';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { SCOPES } from 'permissions';

// constant
const icons = {
    SwagginIcon,
    IconLayoutNavbar,
    StarIcon,
    IconTool,
    IconHeadset,
    AssignmentIcon,
    LibraryBooksIcon,
    IconSwitchHorizontal,
    StorefrontIcon,
    EmailIcon,
    ContactMailIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const swaggin = {
    id: 'Swaggin',
    type: 'group',
    scope: SCOPES.sw,
    children: [
        {
            id: 'swaggin',
            title: <FormattedMessage id="swaggin" />,
            type: 'collapse',
            url: '/swaggin',
            icon: icons.SwagginIcon,
            level: 0,
            children: [
                {
                    id: 'dashboard-swaggin',
                    title: <FormattedMessage id="dashboard" />,
                    type: 'collapse',
                    icon: icons.IconLayoutNavbar,
                    scope: SCOPES.dashboard,
                    level: 1,
                    children: [
                        {
                            id: 'dashboard-swaggin-mongo-chart',
                            title: <FormattedMessage id="mongo-chart" />,
                            type: 'item',
                            icon: icons.IconLayoutNavbar,
                            url: '/Swaggin/Dashboard/MongoChart',
                            scope: SCOPES.app,
                            level: 2
                        },
                        {
                            id: 'dashboard-swaggin-data-studio',
                            title: <FormattedMessage id="looker-studio" />,
                            type: 'item',
                            icon: icons.IconLayoutNavbar,
                            url: '/Swaggin/Dashboard/DataStudio',
                            scope: SCOPES.technical,
                            level: 2
                        }
                    ]
                },
                // {
                //     id: 'subscriptions-swaggin',
                //     title: <FormattedMessage id="subscriptions" />,
                //     type: 'item',
                //     icon: icons.StarIcon,
                //     url: '/Swaggin/Subscriptions',
                //     scope: SCOPES.subscriptions,
                //     level: 1
                // },
                {
                    id: 'costumer-support-swaggin',
                    title: <FormattedMessage id="costumer-support" />,
                    type: 'item',
                    icon: icons.IconTool,
                    url: '/Swaggin/CustomerSupport',
                    scope: SCOPES.customerSupport,
                    level: 1
                },
                {
                    id: 'business-params-swaggin',
                    title: <FormattedMessage id="business-params" />,
                    type: 'item',
                    icon: icons.AssignmentIcon,
                    url: '/Swaggin/BusinessParams',
                    scope: SCOPES.businessParameters,
                    level: 1
                },
                {
                    id: 'reviews-swaggin',
                    title: <FormattedMessage id="reviews" />,
                    type: 'item',
                    icon: icons.LibraryBooksIcon,
                    url: '/Swaggin/Reviews',
                    scope: SCOPES.reviews,
                    level: 1
                },
                {
                    id: 'transactions-swaggin',
                    title: <FormattedMessage id="transactions" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.IconSwitchHorizontal,
                    url: '/Swaggin/Transactions',
                    scope: SCOPES.subscriptions,
                    level: 1
                },
                {
                    id: 'commerces-swaggin',
                    title: <FormattedMessage id="commerces" />,
                    type: 'item',
                    icon: icons.StorefrontIcon,
                    url: '/Swaggin/Commerces',
                    scope: SCOPES.commerces,
                    level: 1
                },
                {
                    id: 'spotifyEmails',
                    title: <FormattedMessage id="spotifyEmails" />,
                    type: 'item',
                    icon: icons.EmailIcon,
                    url: '/Swaggin/SpotifyEmails',
                    scope: SCOPES.spotifyEmails,
                    level: 1
                },
                {
                    id: 'spotifyEmails-history',
                    title: <FormattedMessage id="spotifyEmails" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.EmailIcon,
                    url: '/Swaggin/SpotifyEmails/History',
                    scope: SCOPES.spotifyEmails,
                    level: 1
                },
                {
                    id: 'contact-users',
                    title: <FormattedMessage id="contact-users" />,
                    type: 'item',
                    icon: icons.ContactMailIcon,
                    url: '/Swaggin/ContactUsers',
                    scope: SCOPES.contactUsers,
                    level: 1
                }
            ]
        }
    ]
};

export default swaggin;
