// third-party
import { FormattedMessage } from 'react-intl';

// project imports
import { SCOPES } from 'permissions';

// assets
import EarIcon from '../assets/logos/EarIcon';
import { IconAt, IconDeviceMobileMessage, IconLayoutNavbar, IconMessage, IconTool } from '@tabler/icons';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ChannelIcon from '../assets/icons/ChannelIcon';
import SettingsIcon from '@mui/icons-material/Settings';

const icons = {
    EarIcon,
    IconAt,
    IconDeviceMobileMessage,
    IconLayoutNavbar,
    IconMessage,
    PhoneIphoneIcon,
    ChannelIcon,
    SettingsIcon,
    IconTool
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const ear = {
    id: 'ear',
    type: 'group',
    scope: SCOPES.ear,
    children: [
        {
            id: 'Ear',
            title: <FormattedMessage id="ear" />,
            type: 'collapse',
            url: '/ear',
            icon: icons.EarIcon,
            level: 0,
            children: [
                {
                    id: 'dashboard-ear',
                    title: <FormattedMessage id="dashboard" />,
                    type: 'item',
                    icon: icons.IconLayoutNavbar,
                    url: '/Ear/Dashboard',
                    scope: SCOPES.dashboard,
                    level: 1
                },
                {
                    id: 'configurations',
                    title: <FormattedMessage id="configurations" />,
                    type: 'collapse',
                    icon: icons.SettingsIcon,
                    scope: SCOPES.configuration,
                    level: 1,
                    children: [
                        {
                            id: 'channels-ear',
                            title: <FormattedMessage id="channels-ear" />,
                            type: 'item',
                            icon: icons.ChannelIcon,
                            url: '/Ear/Configurations/Channels',
                            scope: SCOPES.channels,
                            level: 2
                        },
                        {
                            id: 'applications',
                            title: <FormattedMessage id="applications" />,
                            type: 'item',
                            icon: icons.PhoneIphoneIcon,
                            url: '/Ear/Configurations/Applications',
                            scope: SCOPES.applications,
                            level: 2
                        }
                    ]
                },
                {
                    id: 'global-support',
                    title: <FormattedMessage id="costumer-support-general" />,
                    type: 'item',
                    icon: icons.IconTool,
                    url: '/Ear/CustomerSupport/General',
                    scope: SCOPES.dashboard,
                    level: 1
                }
            ]
        }
    ]
};

export default ear;
