const getNotificationLink = (type, appName) => {
    switch (type) {
        case 'validation-request-eden':
            return '/BeSoul/ValidationRequest';
        case 'validation-request-ark':
            return '/BeSoul/ValidationRequest';
        case 'report':
            return '/BeSoul/Reports';
        case 'ticket':
            if (appName === 'Be Soul') {
                return '/BeSoul/CustomerSupport';
            }
            if (appName === 'Swaggin') {
                return '/Swaggin/CustomerSupport';
            }
            return '/Ear/CustomerSupport/General';
        case 'interested-user':
            return '/TechmindsAcademy/UsersInterested';
        case 'B2B-service':
            return '/SU/B2B/Services';
        case 'establishment-payment':
            return '/Admin/Establishment-payments';
        case 'refferal-payment':
            return '/Admin/Affiliate-payments';
        default:
            return '/BeSoul/Reports';
    }
};

export default getNotificationLink;
