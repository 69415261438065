// third-party
import { combineReducers } from 'redux';

// project imports
import snackbarReducer from './slices/snackbar';
import validationsReducer from './slices/validations';
import userReducer from './slices/user';
import menuReducer from './slices/menu';
import subscriptionReducer from './slices/subscriptions';
import reportReducer from './slices/reports';
import testersReducer from './slices/testers';
import ticketsReducer from './slices/tickets';
import chatReducer from './slices/chat';
import interestedUsersReducer from './slices/interestedUsers';
import retailersReducer from './slices/retailers';
import commercesReducer from './slices/commerces';
import reviewsReducer from './slices/reviews';
import packsReducer from './slices/packs';
import paymentsReducer from './slices/payments';
import besoulUsersReducer from './slices/besoulUsers';
import edensReducer from './slices/edens';
import contactUsersReducer from './slices/contactUsers';
import b2bServicesReducer from './slices/b2bServices';
import logsReducer from './slices/logs';
import UnsuscribeReducer from './slices/unsuscribers';
import GroupUnsuscribeReducer from './slices/group_unsuscribers';
import bridgeAIReducer from './slices/bridgeAI';
import establishmentPaymentReducer from './slices/establishment-payment';
import biometricReducer from './slices/biometric';
import referralReducer from './slices/referrals';
import affiliatesReducer from './slices/affiliate-payments';
import notificationsReducer from './slices/notifications';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    snackbar: snackbarReducer,
    validations: validationsReducer,
    user: userReducer,
    subscriptions: subscriptionReducer,
    reports: reportReducer,
    testers: testersReducer,
    menu: menuReducer,
    tickets: ticketsReducer,
    chat: chatReducer,
    interestedUsers: interestedUsersReducer,
    retailers: retailersReducer,
    commerces: commercesReducer,
    reviews: reviewsReducer,
    packs: packsReducer,
    payments: paymentsReducer,
    besoulUsers: besoulUsersReducer,
    edens: edensReducer,
    contactUsers: contactUsersReducer,
    b2bServices: b2bServicesReducer,
    logs: logsReducer,
    bridgeAI: bridgeAIReducer,
    biometric: biometricReducer,
    unsuscribe: UnsuscribeReducer,
    group_unsuscribers: GroupUnsuscribeReducer,
    establishmentPayment: establishmentPaymentReducer,
    referrals: referralReducer,
    affiliates: affiliatesReducer,
    notifications: notificationsReducer
});

export default reducer;
