/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { swagginHttpService } from 'api/swaggin.service';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    packs: []
};

const slice = createSlice({
    name: 'packs',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET TESTERS
        getPacks(state, action) {
            state.packs = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getPacks() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await swagginHttpService.getPacks();
            const { result } = response;
            dispatch(slice.actions.getPacks([result]));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
