/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';
import { swagginHttpService } from 'api/swaggin.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    contactUsers: []
};

const slice = createSlice({
    name: 'contactUsers',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET INTERESTED USERS
        getContactUsers(state, action) {
            state.contactUsers = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getContactUsers() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const {
                result: { interestedCommerces }
            } = await swagginHttpService.getContactUsers();
            dispatch(slice.actions.getContactUsers(interestedCommerces.reverse()));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
