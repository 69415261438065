/* eslint-disable no-restricted-globals */
/* eslint-disable no-alert */
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// third-party
import 'firebase/compat/auth';
import { AES, enc } from 'crypto-js';
import { getAnalytics } from '@firebase/analytics';
import { initializeApp } from 'firebase/app';
import { initializeAppCheck, ReCaptchaEnterpriseProvider, onTokenChanged } from 'firebase/app-check';
import {
    browserSessionPersistence,
    getAuth,
    setPersistence,
    signInWithEmailAndPassword,
    signOut,
    updateEmail,
    updatePassword
} from 'firebase/auth';

// action - state management
import { DISABLE, LOADING, LOGIN, LOGOUT } from 'store/actions';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import { FIREBASE_API, TENANT_ID, RECAPTCHA_PASSWORD, KEY } from 'config';
import { usersHttpService } from 'api/user.service';
import { useNavigate } from 'react-router-dom';
import activateDebugTokenIfApply from 'utils/functions/activateDebugTokenIfApply';

// firebase initialization
const app = initializeApp(FIREBASE_API);
activateDebugTokenIfApply();
const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(RECAPTCHA_PASSWORD),
    isTokenAutoRefreshEnabled: true
});
const auth = getAuth(app);
auth.tenantId = TENANT_ID;
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

// const
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    loading: false
};

// ==============================|| FIREBASE CONTEXT & PROVIDER ||============================== //

const FirebaseContext = createContext(null);

export const FirebaseProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const navigateTo = useNavigate();

    useEffect(
        () => {
            onTokenChanged(appCheck, async (observer) => {
                const appCheckToken = observer.token;
                window.sessionStorage.setItem('firebase:appCheck', appCheckToken);
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    useEffect(
        () =>
            auth.onAuthStateChanged(async (user) => {
                if (user) {
                    const response = await usersHttpService.getProfile();
                    if (user.emailVerified) {
                        if (response.user.status === 'disable') {
                            signOut(auth);
                            dispatch({
                                type: DISABLE
                            });
                        } else {
                            dispatch({
                                type: LOGIN,
                                payload: {
                                    isLoggedIn: true,
                                    user: {
                                        id: response.user.uid,
                                        email: response.user.email,
                                        name: response.user.name,
                                        lastName: response.user.lastName,
                                        role: response.user.role.name,
                                        permissions: response.user.role.permissions,
                                        appsAssigned: response.user.appsAssigned,
                                        promoCode: response.user.promoCode,
                                        commercializationType: response.user.commercializationType
                                    }
                                }
                            });
                        }
                    } else {
                        signOut(auth);
                        navigateTo(`/ActivateAccount/${user.uid}`, {
                            state: {
                                retailer: response.user.role.name === 'Retailer',
                                // eslint-disable-next-line no-underscore-dangle
                                id: response.user._id
                            }
                        });
                    }
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dispatch]
    );

    const firebaseEmailPasswordSignIn = async (email, password) => {
        dispatch({ type: LOADING });
        await setPersistence(auth, browserSessionPersistence);
        if (password.length > 30) {
            const createdAt = new Date(Number(AES.decrypt(password, KEY).toString(enc.Utf8)));
            const now = new Date();
            createdAt.setHours(createdAt.getHours() + 120);
            if (now > createdAt) {
                alert('Your invitation have been expired');
            } else {
                return signInWithEmailAndPassword(auth, email, password);
            }
        }
        return signInWithEmailAndPassword(auth, email, password);
    };

    const firebaseUpdateEmail = async (email) => updateEmail(auth.currentUser, email);

    const firebaseUpdatePassword = async (newPassword) => updatePassword(auth.currentUser, newPassword);

    const logout = () => {
        localStorage.removeItem('global-states');
        localStorage.removeItem('lastInteractionTimeStored');
        signOut(auth);
    };

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <FirebaseContext.Provider
            value={{
                ...state,
                firebaseEmailPasswordSignIn,
                firebaseUpdateEmail,
                firebaseUpdatePassword,
                logout
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

FirebaseProvider.propTypes = {
    children: PropTypes.node
};

export default FirebaseContext;
