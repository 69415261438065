/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';
import { beSoulHttpService } from 'api/besoul.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    edens: [],
    history: []
};

const slice = createSlice({
    name: 'edens',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET EDENS
        getEdens(state, action) {
            state.edens = action.payload;
        },

        // GET EDENS HISTORY
        getHistoryEdens(state, action) {
            state.history = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getEdens() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            //  TO DO: get edens landing
            const response = await beSoulHttpService.getPendingEdens();
            dispatch(slice.actions.getEdens(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getEdensHistory() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            //  TO DO: get edens landing history
            const response = await beSoulHttpService.getEdensHistory();
            dispatch(slice.actions.getHistoryEdens(response.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
