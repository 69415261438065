import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE_GROUP = '/sendgrid/group';

export class GroupUnsuscribeService extends AxiosRepository {
    async getGroupUnsuscribers() {
        return super.getWithClient(`${RESOURCE_GROUP}`);
    }
}

export const GroupUnsuscribeHttpService = new GroupUnsuscribeService();
