import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'b2bservices';

export class B2BService extends AxiosRepository {
    async getContactServices() {
        return super.getWithClient(`${RESOURCE}`);
    }
}

export const b2bServicesHttpService = new B2BService();
