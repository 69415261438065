import axios from 'axios';

export default function createAxiosClient({
    options,
    getCurrentAccessToken,
    getAppValidationToken
    // getCurrentRefreshToken,
    // refreshTokenUrl,
    // logout,
    // setRefreshedTokens
}) {
    const client = axios.create(options);

    client.interceptors.request.use(
        (config) => {
            if (config.authorization !== false) {
                const token = getCurrentAccessToken();
                if (token) {
                    config.headers.Authorization = `Bearer ${token}`;
                }
            }
            const appCheckToken = getAppValidationToken();
            config.headers['X-Firebase-AppCheck'] = appCheckToken;
            return config;
        },
        (error) => Promise.reject(error)
    );

    return client;
}
