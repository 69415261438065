// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <svg width="56" height="30" viewBox="0 0 56 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M19.284 13.477C17.9146 12.7565 15.8004 12.0613 12.9414 11.3913C10.0824 10.7213 8.28279 10.0761 7.5425 9.45563C6.9613 8.96754 6.67025 8.38219 6.66935 7.69958C6.66103 7.34582 6.7421 6.99565 6.90509 6.68125C7.06809 6.36685 7.30779 6.09831 7.60216 5.90031C8.56932 5.2015 9.90572 4.85209 11.6114 4.85209C13.2655 4.85209 14.5065 5.17853 15.3345 5.83142C16.1624 6.48431 16.7003 7.5528 16.9479 9.03688L22.8268 8.78023C22.7364 6.11824 21.767 3.98893 19.9186 2.39228C18.0701 0.795626 15.3209 -0.00179803 11.671 3.04406e-06C9.4357 3.04406e-06 7.52714 0.336353 5.94533 1.00905C4.36352 1.68175 3.15231 2.66064 2.3117 3.9457C1.48995 5.17223 1.05162 6.61395 1.05213 8.08862C1.05213 10.3823 1.94517 12.327 3.73125 13.9227C5.00212 15.0565 7.21213 16.0124 10.3613 16.7905C12.8099 17.3957 14.3782 17.8176 15.066 18.0562C16.0721 18.4119 16.7766 18.8302 17.1798 19.3111C17.5886 19.804 17.804 20.428 17.7858 21.0671C17.7858 22.1343 17.3059 23.0668 16.3459 23.8647C15.386 24.6625 13.9597 25.0597 12.0669 25.0561C10.2808 25.0561 8.86173 24.6058 7.8096 23.7053C6.75747 22.8047 6.05967 21.4026 5.7162 19.4989L0 20.0527C0.384153 23.2946 1.56192 25.7634 3.5333 27.4591C5.50468 29.1548 8.32979 30.0018 12.0086 30C14.5395 30 16.6505 29.6474 18.3417 28.9423C20.0329 28.2372 21.3435 27.1593 22.2736 25.7085C23.1817 24.3202 23.6646 22.6986 23.6634 21.0415C23.6634 19.2098 23.2765 17.6708 22.5028 16.4244C21.718 15.1693 20.6054 14.1504 19.284 13.477Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#002E81'}
                />
                <path
                    d="M54.156 26.088C53.7961 26.0912 53.4451 26.2005 53.1474 26.402C52.8497 26.6035 52.6185 26.8883 52.4829 27.2204C52.3474 27.5526 52.3135 27.9173 52.3856 28.2686C52.4577 28.62 52.6325 28.9422 52.888 29.1947C53.1435 29.4472 53.4684 29.6188 53.8216 29.6877C54.1748 29.7567 54.5406 29.7199 54.8729 29.5822C55.2052 29.4444 55.4891 29.2118 55.6889 28.9135C55.8888 28.6153 55.9955 28.2648 55.9958 27.9062C55.9949 27.6664 55.9466 27.4292 55.8537 27.208C55.7607 26.9869 55.6249 26.7861 55.4541 26.6173C55.2832 26.4484 55.0806 26.3148 54.8579 26.224C54.6351 26.1332 54.3966 26.087 54.156 26.088ZM54.1438 29.3367C53.7597 29.3355 53.3919 29.1823 53.1212 28.9108C52.8506 28.6394 52.6992 28.2719 52.7005 27.8893C52.7017 27.5067 52.8555 27.1403 53.1279 26.8706C53.4004 26.6009 53.7692 26.4501 54.1532 26.4514C54.96 26.4514 55.5416 27.1106 55.5348 27.9157C55.5281 28.7207 54.9396 29.3421 54.1438 29.3367Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#002E81'}
                />
                <path
                    d="M54.5632 27.9522V27.9292C54.6632 27.9112 54.7545 27.8608 54.8229 27.7858C54.8913 27.7108 54.9329 27.6155 54.9414 27.5146C54.9445 27.4396 54.9304 27.3649 54.9004 27.2962C54.8703 27.2274 54.825 27.1663 54.7679 27.1174C54.5758 27.0125 54.3572 26.9655 54.1388 26.9823C53.9054 26.9774 53.672 26.9946 53.4419 27.0337L53.4297 28.8397H53.85V28.113H54.0479C54.2812 28.113 54.391 28.2021 54.4222 28.4007C54.4456 28.5541 54.4913 28.7034 54.5577 28.8437H55.0119C54.9408 28.7018 54.8949 28.5487 54.8764 28.3912C54.8384 28.1521 54.7394 28.0198 54.5632 27.9522ZM54.0669 27.8171H53.8676V27.2984C53.9439 27.2831 54.0217 27.2763 54.0995 27.2782C54.3706 27.2782 54.4859 27.3916 54.4845 27.5564C54.4872 27.7428 54.2988 27.8185 54.0669 27.8171Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#002E81'}
                />
                <path
                    d="M45.114 16.546C45.114 18.8397 45.0281 20.4804 44.8563 21.4683C44.6803 22.4634 44.1348 23.356 43.3283 23.9687C42.4814 24.6495 41.2245 24.9899 39.5578 24.9899C37.9172 24.9899 36.6242 24.6297 35.6787 23.9092C34.7455 23.2122 34.1056 22.1944 33.8836 21.0536C33.7372 20.3143 33.6644 18.6996 33.6653 16.2096V0.513306H27.7945L24.8564 3.44185V5.65311H27.7945V15.9733C27.7945 19.2152 27.9862 21.6664 28.3694 23.327C28.6652 24.509 29.2287 25.6078 30.0167 26.5392C30.851 27.5604 31.985 28.3907 33.4185 29.0301C34.8521 29.6695 36.9591 29.9892 39.7394 29.9892C42.0416 29.9892 43.9068 29.6956 45.335 29.1085C46.7631 28.5213 47.9029 27.7338 48.7544 26.7459C49.6085 25.7553 50.1961 24.5428 50.517 23.1082C50.8378 21.6736 50.9965 19.2156 50.9929 15.7342V0.513306H45.114V16.546Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : '#002E81'}
                />
            </g>
            <defs>
                <clipPath id="clip0_4635_65944">
                    <rect width="56" height="30" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Logo;
