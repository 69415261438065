// third-party
import { FormattedMessage } from 'react-intl';

// project imports
import { SCOPES } from 'permissions';

// assets
import BeSoulIcon from '../assets/logos/BeSoulIcon';
import { IconFileText, IconLayoutNavbar, IconHandStop, IconTool, IconCoin } from '@tabler/icons';
import StarIcon from '@mui/icons-material/Star';
import PeopleIcon from '@mui/icons-material/People';
import FileCopyOutlinedIcon from '@mui/icons-material/FileCopyOutlined';

// constant
const icons = {
    BeSoulIcon,
    IconFileText,
    FileCopyOutlinedIcon,
    IconLayoutNavbar,
    StarIcon,
    IconHandStop,
    IconCoin,
    PeopleIcon,
    IconTool
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const beSoul = {
    id: 'beSoul',
    type: 'group',
    scope: SCOPES.bs,
    children: [
        {
            id: 'BeSoul',
            title: <FormattedMessage id="be-soul" />,
            type: 'collapse',
            url: '/besoul',
            icon: icons.BeSoulIcon,
            level: 0,
            children: [
                {
                    id: 'dashboard-besoul',
                    title: <FormattedMessage id="dashboard" />,
                    type: 'collapse',
                    icon: icons.IconLayoutNavbar,
                    scope: SCOPES.dashboard,
                    level: 1,
                    children: [
                        {
                            id: 'dashboard-besoul-general',
                            title: <FormattedMessage id="general" />,
                            type: 'item',
                            icon: icons.IconLayoutNavbar,
                            url: '/Besoul/Dashboard/General',
                            scope: SCOPES.general,
                            level: 2
                        },
                        {
                            id: 'dashboard-besoul-general-history',
                            title: <FormattedMessage id="history" />,
                            type: 'item',
                            disabled: true,
                            icon: icons.FileCopyOutlinedIcon,
                            url: '/BeSoul/Dashboard/General/History',
                            scope: SCOPES.genera,
                            level: 2
                        },
                        {
                            id: 'dashboard-besoul-mongo-chart',
                            title: <FormattedMessage id="mongo-chart" />,
                            type: 'item',
                            icon: icons.IconLayoutNavbar,
                            url: '/Besoul/Dashboard/MongoChart',
                            scope: SCOPES.app,
                            level: 2
                        },
                        {
                            id: 'dashboard-besoul-data-studio',
                            title: <FormattedMessage id="looker-studio" />,
                            type: 'item',
                            icon: icons.IconLayoutNavbar,
                            url: '/Besoul/Dashboard/DataStudio',
                            scope: SCOPES.technical,
                            level: 2
                        }
                    ]
                },
                {
                    id: 'validation-request',
                    title: <FormattedMessage id="validation-request" />,
                    type: 'item',
                    icon: icons.IconFileText,
                    url: '/BeSoul/ValidationRequest',
                    scope: SCOPES['death-certificate'],
                    level: 1
                },
                {
                    id: 'validation-history',
                    title: <FormattedMessage id="validation-history" />,
                    type: 'item',
                    icon: icons.FileCopyOutlinedIcon,
                    url: '/BeSoul/ValidationHistory',
                    scope: SCOPES['death-certificate'],
                    level: 1
                },
                {
                    id: 'validation-request-death-certificate',
                    title: <FormattedMessage id="validation-request-death-certificate" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.FileCopyOutlinedIcon,
                    url: '/BeSoul/ValidationHistory/Detail/:id',
                    scope: SCOPES['death-certificate'],
                    level: 1
                },
                {
                    id: 'validation-request-funeral-data',
                    title: <FormattedMessage id="validation-request-funeral-data" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.FileCopyOutlinedIcon,
                    url: '/BeSoul/ValidationHistory/Detail/:id',
                    scope: SCOPES['death-certificate'],
                    level: 1
                },
                // {
                //     id: 'web-edens',
                //     title: <FormattedMessage id="web-edens" />,
                //     type: 'item',
                //     icon: icons.FileCopyOutlinedIcon,
                //     url: '/BeSoul/WebEdens',
                //     scope: SCOPES.edensWeb,
                //     level: 1
                // },
                // {
                //     id: 'web-edens-history',
                //     title: <FormattedMessage id="web-edens" />,
                //     type: 'item',
                //     disabled: true,
                //     icon: icons.FileCopyOutlinedIcon,
                //     url: '/BeSoul/WebEdensHistory',
                //     scope: SCOPES.edensWeb,
                //     level: 1
                // },
                {
                    id: 'subscriptions',
                    title: <FormattedMessage id="subscriptions" />,
                    type: 'item',
                    icon: icons.StarIcon,
                    url: '/BeSoul/Subscriptions',
                    scope: SCOPES.subscriptions,
                    level: 1
                },
                {
                    id: 'reports',
                    title: <FormattedMessage id="reports" />,
                    type: 'item',
                    icon: icons.IconHandStop,
                    url: '/BeSoul/Reports',
                    scope: SCOPES.reports,
                    level: 1
                },
                {
                    id: 'reports-history',
                    title: <FormattedMessage id="reports-history" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.IconHandStop,
                    url: '/BeSoul/ReportsHistory',
                    scope: SCOPES.reports,
                    level: 1
                },
                {
                    id: 'testers',
                    title: <FormattedMessage id="testers" />,
                    type: 'item',
                    icon: icons.PeopleIcon,
                    url: '/BeSoul/Testers',
                    scope: SCOPES.testers,
                    level: 1
                },
                {
                    id: 'costumer-support-besoul',
                    title: <FormattedMessage id="costumer-support" />,
                    type: 'item',
                    icon: icons.IconTool,
                    url: '/BeSoul/CustomerSupport',
                    scope: SCOPES.customerSupport,
                    level: 1
                },
                {
                    id: 'referral-program',
                    title: <FormattedMessage id="referral-program" />,
                    type: 'item',
                    icon: icons.IconTool,
                    url: '/BeSoul/ReferralProgram',
                    scope: SCOPES.customerSupport,
                    level: 1
                }
            ]
        }
    ]
};

export default beSoul;
