import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'notifications';

export class NotificationsService extends AxiosRepository {
    async getNotifications(skip, limit) {
        return super.getWithClient(`${RESOURCE}?skip=${skip}&limit=${limit}`);
    }

    async updateNotifications(payload) {
        return super.putWithClient(payload, RESOURCE);
    }
}

export const notificationsHttpService = new NotificationsService();
