const SwagginIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={55} fill="none" {...props}>
        <g filter="url(#a)">
            <circle cx={27} cy={23.5} r={19} fill="#fff" />
            <path fill="#FFC900" d="M17.709 35.677v-4.026h7.046a4.027 4.027 0 0 1 4.027 4.026H17.71Z" />
            <path
                fill="#FF8D12"
                d="M21.695 13.335v15.787l-3.276 1.331a.545.545 0 0 1-.75-.504V13.397c0-1.116.882-2.066 1.998-2.074a2.01 2.01 0 0 1 2.028 2.012Z"
            />
            <path fill="#FF4D82" d="M26.575 21.699v5.438l-4.027 1.638V21.7a2.012 2.012 0 0 1 3.438-1.424c.363.364.588.868.588 1.423Z" />
            <path fill="#00C0BD" d="M31.453 21.699v7.075l-4.027-1.638v-5.438a2.015 2.015 0 0 1 3.438-1.424c.363.366.589.87.589 1.425Z" />
            <path
                fill="#2D6CCE"
                d="M36.331 13.335V29.95c0 .387-.392.65-.75.504l-3.276-1.331V13.395c0-1.116.882-2.064 1.997-2.072a2.01 2.01 0 0 1 2.03 2.012Z"
            />
        </g>
        <defs>
            <filter id="a" width={54} height={54} x={0} y={0.5} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={4} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4641_65571" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_4641_65571" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default SwagginIcon;
