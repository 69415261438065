const BridgeIAIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} fill="none" {...props}>
        <g filter="url(#a)">
            <circle cx={27} cy={23} r={19} fill="#fff" />
        </g>
        <path
            fill="#335CD4"
            d="M30 20h-6v6h6v-6Zm-2 4h-2v-2h2v2Zm8-2v-2h-2v-2c0-1.1-.9-2-2-2h-2v-2h-2v2h-2v-2h-2v2h-2c-1.1 0-2 .9-2 2v2h-2v2h2v2h-2v2h2v2c0 1.1.9 2 2 2h2v2h2v-2h2v2h2v-2h2c1.1 0 2-.9 2-2v-2h2v-2h-2v-2h2Zm-4 6H22V18h10v10Z"
        />
        <defs>
            <filter id="a" width={54} height={54} x={0} y={0} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={4} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_11067_23120" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_11067_23120" result="shape" />
            </filter>
        </defs>
    </svg>
);
export default BridgeIAIcon;
