// third-party
import { FormattedMessage } from 'react-intl';

// project imports
import { SCOPES } from 'permissions';

// assets
import SalesIcon from '../assets/logos/SalesIcon';
import { IconUsers } from '@tabler/icons';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import RetailersManageIcon from '../assets/icons/RetailesManageIcon';

const icons = {
    SalesIcon,
    AccountCircleIcon,
    RetailersManageIcon,
    IconUsers
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const sales = {
    id: 'sales',
    type: 'group',
    scope: SCOPES.sales,
    children: [
        {
            id: 'Sales',
            title: <FormattedMessage id="sales" />,
            type: 'collapse',
            url: '/sales',
            icon: icons.SalesIcon,
            level: 0,
            children: [
                {
                    id: 'aplications',
                    title: <FormattedMessage id="retailers-management" />,
                    type: 'item',
                    icon: icons.RetailersManageIcon,
                    url: '/Sales/Aplications',
                    scope: SCOPES.retailersManagement,
                    level: 1
                },
                {
                    id: 'retailers-management',
                    title: <FormattedMessage id="retailers-management" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.RetailersManageIcon,
                    url: '/Sales/RetailersManagement',
                    scope: SCOPES.retailersManagement,
                    level: 1
                },
                {
                    id: 'retailers-management-form',
                    title: <FormattedMessage id="retailers-management" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.RetailersManageIcon,
                    url: '/Sales/RetailersManagement/New',
                    scope: SCOPES.retailersManagement,
                    level: 1
                },
                {
                    id: 'retailers-management-form-edit',
                    title: <FormattedMessage id="retailers-management" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.RetailersManageIcon,
                    url: '/Sales/RetailersManagement/Edit',
                    scope: SCOPES.retailersManagement,
                    level: 1
                },
                {
                    id: 'retailers',
                    title: <FormattedMessage id="retailers" />,
                    type: 'item',
                    icon: icons.IconUsers,
                    url: '/Sales/Retailers/Dashboard',
                    scope: SCOPES.retailer,
                    level: 1
                },
                {
                    id: 'retailers-besoul',
                    title: <FormattedMessage id="retailers" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.IconUsers,
                    url: '/Sales/Retailers/Be%20Soul/Subscribers',
                    scope: SCOPES.retailer,
                    level: 1
                },
                {
                    id: 'retailers-swaggin',
                    title: <FormattedMessage id="retailers" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.IconUsers,
                    url: '/Sales/Retailers/Swaggin/Subscribers',
                    scope: SCOPES.retailer,
                    level: 1
                },
                {
                    id: 'retailers-details',
                    title: <FormattedMessage id="retailers-details" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.IconUsers,
                    url: '/Sales/Retailers/Detail',
                    scope: SCOPES.retailer,
                    level: 1
                },
                {
                    id: 'retailers-verify',
                    title: <FormattedMessage id="retailers-verify" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.IconUsers,
                    url: '/Sales/Retailers/PaymentMethod/Verify',
                    scope: SCOPES.retailer,
                    level: 1
                },
                {
                    id: 'retailers-history',
                    title: <FormattedMessage id="retailers-history" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.IconUsers,
                    url: '/Sales/Retailers/History',
                    scope: SCOPES.retailer,
                    level: 1
                }
            ]
        }
    ]
};

export default sales;
