import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Collapse, Divider, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

// project imports
import NavItem from '../NavItem';
import PermissionGuard from 'utils/route-guard/PermissionGuard';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level, parentScope }) => {
    const theme = useTheme();

    const [open, setOpen] = useState(false);
    const [selected, setSelected] = useState(null);

    const handleClick = () => {
        setOpen(!open);
        setSelected(!open ? menu.id : null);
    };

    const { pathname } = useLocation();
    const checkOpenForParent = (child, id) => {
        child.forEach((item) => {
            if (item.url === pathname) {
                setOpen(true);
                setSelected(id);
            }
        });
    };

    // menu collapse for sub-levels
    useEffect(() => {
        const childrens = menu.children ? menu.children : [];
        childrens.forEach((item) => {
            if (item.children?.length) {
                checkOpenForParent(item.children, menu.id);
            }
            if (pathname && pathname.includes('product-details')) {
                if (item.url && item.url.includes('product-details')) {
                    setSelected(menu.id);
                    setOpen(true);
                }
            }
            if (item.url === pathname) {
                setSelected(menu.id);
                setOpen(true);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children]);

    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return (
                    <PermissionGuard key={item.id} scopes={[`${parentScope}.${item.scope}`]} isGroup>
                        <NavCollapse
                            key={item.id}
                            menu={item}
                            level={level + 1}
                            parentScope={item.scope ? `${parentScope}.${item.scope}` : parentScope}
                        />
                    </PermissionGuard>
                );
            case 'item':
                return (
                    <PermissionGuard key={item.id} scopes={[`${parentScope}.${item.scope}`]}>
                        <NavItem key={item.id} item={item} level={level + 1} />
                    </PermissionGuard>
                );
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} style={{ color: selected === menu.id ? '#335CD4' : '#BDC8F0' }} size="20px" />
    ) : (
        <FiberManualRecordIcon
            sx={{
                width: selected === menu.id ? 8 : 6,
                height: selected === menu.id ? 8 : 6
            }}
            fontSize={level > 0 ? 'inherit' : 'medium'}
        />
    );

    const getPaddingByLevel = (level) => {
        if (level === 1) {
            return '18px';
        }
        if (level === 2) {
            return '26px';
        }

        return '12px';
    };

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `12px`,
                    alignItems: 'center',
                    backgroundColor: level > 1 ? 'transparent !important' : 'inherit',
                    paddingY: '8px',
                    paddingX: getPaddingByLevel(level)
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon>{menuIcon}</ListItemIcon>
                <ListItemText
                    primary={
                        <Typography
                            variant={selected === menu.id ? 'h4' : 'body1'}
                            fontWeight={selected === menu.id ? 'Bold' : 'Medium'}
                            color={selected === menu.id ? '#335CD4' : '#BDC8F0 !important'}
                            sx={{ my: 'auto', mx: level === 0 ? '14px' : 0 }}
                        >
                            {menu.title}
                        </Typography>
                    }
                    secondary={
                        menu.caption && (
                            <Typography variant="caption" sx={{ ...theme.typography.subMenuCaption }} display="block" gutterBottom>
                                {menu.caption}
                            </Typography>
                        )
                    }
                />
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                {open && (
                    <>
                        <List
                            component="div"
                            disablePadding
                            sx={{
                                position: 'relative',
                                '&:after': {
                                    content: "''",
                                    position: 'absolute',
                                    left: '32px',
                                    top: 0,
                                    height: '100%',
                                    width: '1px',
                                    opacity: theme.palette.mode === 'dark' ? 0.2 : 1
                                },
                                paddingLeft: level > 1 ? level * 0.8 : 0
                            }}
                        >
                            {menus}
                        </List>
                        {level <= 1 && <Divider sx={{ mt: 0.25, mb: 1.25, borderColor: '#8a8888', borderWidth: '0.5px' }} />}
                    </>
                )}
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number,
    parentScope: PropTypes.string
};

export default NavCollapse;
