// third-party
import { FormattedMessage } from 'react-intl';

// assets
import TechmindsAcademyIcon from '../assets/logos/TechmindsAcademyIcon';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { SCOPES } from 'permissions';

// constant
const icons = {
    TechmindsAcademyIcon,
    ContactMailIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const techmindsAcademy = {
    id: 'techmindsAcademy',
    type: 'group',
    scope: SCOPES.tma,
    children: [
        {
            id: 'TechmindsAcademy',
            title: <FormattedMessage id="techminds-academy" />,
            type: 'collapse',
            url: '/techmindsAcademy',
            icon: icons.TechmindsAcademyIcon,
            level: 0,
            children: [
                {
                    id: 'interested-users',
                    title: <FormattedMessage id="interested-users" />,
                    type: 'item',
                    icon: icons.ContactMailIcon,
                    url: '/TechmindsAcademy/UsersInterested',
                    scope: SCOPES.interestedUsers,
                    level: 1
                }
            ]
        }
    ]
};

export default techmindsAcademy;
