/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { beSoulHttpService } from 'api/besoul.service';
import { swagginHttpService } from 'api/swaggin.service';
import getPlanName from 'utils/functions/getPlanName';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    subscriptionData: [],
    subscribers: [],
    swagginSubscriptionData: [],
    plansSummary: [],
    swagginPlansSummary: [],
    retailerSubscriptionHistory: [],
    retailersStats: {},
    balance: 0
};

const slice = createSlice({
    name: 'subscriptions',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET RESUME FROM SUBSCRIPTIONS
        getPlansSummary(state, action) {
            state.plansSummary = action.payload;
        },

        // GET ALL DATA FROM SUBSCRIPTIONS
        getSubscriptionsData(state, action) {
            state.subscriptionData = action.payload;
        },

        // GET RESUME FROM SUBSCRIPTIONS
        getSwagginPlansSummary(state, action) {
            state.swagginPlansSummary = action.payload;
        },

        // GET ALL DATA FROM SUBSCRIPTIONS
        getSwagginSubscriptionsData(state, action) {
            state.swagginSubscriptionData = action.payload;
        },

        // GET ALL DATA FROM SUBSCRIPTIONS
        getRetailerSubscriptionHistory(state, action) {
            state.retailerSubscriptionHistory = action.payload;
        },

        // GET RETAILER STATS
        getRetailerStats(state, action) {
            state.retailersStats = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

const parseData = (data) => {
    const outputArray = [];
    for (const value of data) {
        outputArray.push({
            name: value.userId.fullName,
            subscriptionDate: value.startDate,
            plan: getPlanName(value.productId),
            email: value.userId.email,
            phoneNumber: value.userId.phone ? `+${value.userId.phone.code} ${value.userId.phone.number}` : '-',
            paymentMethod: value.app === 'android' ? 'Google' : 'Apple',
            promoCode: value.promoCode ? value.promoCode : '-',
            expDate: value.endDate,
            accountStatus: !value.isExpired
        });
    }

    return outputArray;
};

function calculateIncome(amountInMicro) {
    return amountInMicro / 1000000;
}

const parseDataRetailerSubscriptionHistory = (data) => {
    const outputArray = [];
    for (const value of data) {
        outputArray.push({
            subscriber: value.userId.fullName,
            subscriberEmail: value.userId.email,
            subscriptionType: getPlanName(value.productId),
            activationDate: value.startDate,
            expireDate: value.endDate,
            paymentRecurrence: 'yearly',
            incomes: calculateIncome(JSON.parse(value.validationResponse).priceAmountMicros)
        });
    }

    return outputArray;
};

export function getSubscriptionData() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await beSoulHttpService.getSubscription();
            const { beginning, route, top } = response.data;
            dispatch(
                slice.actions.getPlansSummary([
                    { name: 'Souvenir', userRegistered: beginning },
                    { name: 'History', userRegistered: route },
                    { name: 'Legend', userRegistered: top }
                ])
            );
            dispatch(slice.actions.getSubscriptionsData(parseData(response.data.subscriptions)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getSwagginSubscriptionData() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await swagginHttpService.getSubscription();
            const { free, premium, third, fourth } = response.data;
            dispatch(
                slice.actions.getSwagginPlansSummary([
                    { name: 'free', userRegistered: free },
                    { name: 'premium', userRegistered: premium },
                    { name: 'third', userRegistered: third },
                    { name: 'fourth', userRegistered: fourth }
                ])
            );
            dispatch(slice.actions.getSwagginSubscriptionsData(parseData(response.data.subscriptions)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getRetailerSubscriptionHistory() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await beSoulHttpService.getSubscriptionByRetailer();
            dispatch(slice.actions.getRetailerSubscriptionHistory(parseDataRetailerSubscriptionHistory(response.data.subscriptions)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getRetailerStats() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await beSoulHttpService.getStats();
            dispatch(slice.actions.getRetailerStats(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
