/* eslint-disable import/prefer-default-export */
export const SCOPES = {
    admin: 'admin',
    bs: 'bs',
    sw: 'sw',
    tma: 'tma',
    su: 'su',
    ear: 'ear',
    sales: 'sales',
    bridgeAI: 'bridgeAI',
    biometric: 'biometric',
    // besoul
    general: 'general',
    'death-certificate': 'death-certificate',
    edensWeb: 'edensWeb',
    reports: 'reports',
    testers: 'testers',
    // swaggin
    businessParameters: 'businessParameters',
    reviews: 'reviews',
    commerces: 'commerces',
    spotifyEmails: 'spotifyEmails',
    contactUsers: 'contactUsers',
    // tma
    interestedUsers: 'interestedUsers',
    // su
    b2bServices: 'b2bServices',
    // ear
    configuration: 'configuration',
    channels: 'channels',
    applications: 'applications',
    // sales
    retailersManagement: 'retailersManagement',
    merchant: 'merchant',
    retailer: 'retailer',
    // admin
    account: 'account',
    usersManagement: 'usersManagement',
    users: 'users',
    rolesManagement: 'rolesManagement',
    logs: 'logs',
    'establishment-payment': 'establishment-payment',
    establishment: 'establishment',
    affiliate: 'affiliate',
    sendgrid: 'sendgrid',
    global: 'global',
    group: 'group',
    // bridgeAI
    prompts: 'prompts',
    // biometric
    transactions: 'transactions',
    // general
    dashboard: 'dashboard',
    app: 'app',
    technical: 'technical',
    subscriptions: 'subscriptions',
    customerSupport: 'customerSupport'
};
