const getPlanName = (id) => {
    if (id.includes('1_eden_beginning_1y')) return 'Souvenir';
    if (id.includes('4_eden_top_20y')) return 'Legend';
    if (id.includes('3_eden_route_3y')) return 'History';
    if (id === '1_swaggin_free_1w') return 'Free';
    if (id === '2_swaggin_premium_1m') return 'Premium';
    if (id === '3_swaggin_third_1y') return 'Third';
    if (id === '4_swaggin_fourth_2y') return 'Fourth';
    return '';
};

export default getPlanName;
