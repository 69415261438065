const EarIcon = (props) => (
    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g filter="url(#filter0_d_4735_4247)">
            <circle cx="27" cy="23" r="19" fill="white" />
            <path
                d="M31.241 35.7477L27.7568 33.1217C30.3725 30.2903 31.8133 26.6952 31.8133 23C31.8133 19.3048 30.3725 15.7097 27.7568 12.8782L31.241 10.2523C34.5368 13.8194 36.3513 18.3463 36.3513 23C36.3513 27.6536 34.5361 32.1806 31.241 35.7477Z"
                fill="#E2AE4F"
            />
            <path
                d="M26.218 31.9684L22.7339 29.3424C24.3726 27.5689 25.275 25.3164 25.275 23.0006C25.275 20.6848 24.3726 18.4337 22.7339 16.6601L26.218 14.0341C28.5361 16.5434 29.8122 19.7276 29.8122 23.0006C29.8122 26.2736 28.5354 29.4598 26.218 31.9684Z"
                fill="#C54F4F"
            />
            <path
                d="M21.1316 28.1419L17.6504 25.5132C18.3091 24.8015 18.657 23.9324 18.657 22.9999C18.657 22.0675 18.2988 21.189 17.6489 20.4854L21.1331 17.8594C22.4624 19.2988 23.1949 21.1243 23.1949 22.9999C23.1949 24.8756 22.4624 26.7045 21.1316 28.1419Z"
                fill="#488087"
            />
        </g>
        <defs>
            <filter
                id="filter0_d_4735_4247"
                x="0"
                y="0"
                width="54"
                height="54"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="4" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_4735_4247" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_4735_4247" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default EarIcon;
