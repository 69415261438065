export const BACKEND_HOST = process.env.REACT_APP_ADMIN_BACKEND_HOST;
export const BACKEND_API_KEY = process.env.REACT_APP_ADMIN_BACKEND_HOST_API_KEY;
export const BACKEND_EAR = process.env.REACT_APP_SOCKET_SERVER;

export const MT_WEB_DOMAIN = process.env.REACT_APP_MT_FRONTEND_URL;
export const KEY = process.env.REACT_APP_KEY;
export const TENANT_ID = process.env.REACT_APP_ADMIN_TENANT_ID;

export const APP_CHECK_DEBUG_TOKEN = process.env.REACT_APP_FIREBASE_APP_CHECK_DEBUG_TOKEN;
export const RECAPTCHA_PASSWORD = process.env.REACT_APP_RECAPTCHA_ENTERPRISE_APP_WEB_PASSWORD;

export const MONGO_CHART_URL = process.env.REACT_APP_MONGO_CHART_BASE_URL_ID;
export const MONGO_CHART_EDEN_ID = process.env.REACT_APP_AMOUNT_OF_EDEN_CHART_ID;
export const MONGO_CHART_ARKS_ID = process.env.REACT_APP_AMOUNT_OF_ARKS_BY_TYPE_CHART_ID;
export const MONGO_CHART_KEYS_ID = process.env.REACT_APP_AMOUNT_OF_KEYS_CHART_ID;
export const MONGO_CHART_USERS_ID = process.env.REACT_APP_AMOUNT_OF_USERS_CHART_ID;
export const MONGO_CHART_USERS_TYPE_ID = process.env.REACT_APP_AMOUNT_OF_USERS_TYPE_CHART_ID;

export const MONGO_CHART_CIRCLES_ID = process.env.REACT_APP_AMOUNT_OF_CIRCLES_CHART_ID;
export const MONGO_CHART_FILES_ID = process.env.REACT_APP_AMOUNT_OF_FILES_CHART_ID;
export const MONGO_CHART_ONBOARDING_ID = process.env.REACT_APP_AMOUNT_OF_ONBOARDING_CHART_ID;
export const MONGO_CHART_POSTS_ID = process.env.REACT_APP_AMOUNT_OF_POSTS_CHART_ID;
export const MONGO_CHART_SUBSCRIPTIONS_ID = process.env.REACT_APP_AMOUNT_OF_SUBSCRIPTIONS_CHART_ID;

export const MONGO_CHART_CUSTOMER_PER_COMMERCE = process.env.REACT_APP_MONGO_CHART_CUSTOMER_PER_COMMERCE;
export const MONGO_CHART_TOP_SALES_CUSTOMERS = process.env.REACT_APP_MONGO_CHART_TOP_SALES_CUSTOMERS;
export const MONGO_CHART_TOP_SALES_COMMERCE = process.env.REACT_APP_MONGO_CHART_TOP_SALES_COMMERCE;
export const MONGO_CHART_COMMERCE_BY_COUNTRY = process.env.REACT_APP_MONGO_CHART_COMMERCE_BY_COUNTRY;
export const MONGO_CHART_COMPARATIVE_ANALISYS_PACKS = process.env.REACT_APP_MONGO_CHART_COMPARATIVE_ANALISYS_PACKS;
export const MONGO_CHART_PACKS_BY_COMMERCE_TYPE = process.env.REACT_APP_MONGO_CHART_PACKS_BY_COMMERCE_TYPE;
export const MONGO_CHART_EARNINGS_BY_PACKS = process.env.REACT_APP_MONGO_CHART_EARNINGS_BY_PACKS;

export const LOOKER_DASHBOARD_URL = process.env.REACT_APP_LOOKER_DASHBOARD;

export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export const FIREBASE_API = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

// basename: only at build time to set, and Don't add '/' at end off BASENAME for breadcrumbs, also Don't put only '/' use blank('') instead,
// like '/berry-material-react/react/default'
export const BASE_PATH = '';

export const DASHBOARD_PATH = '/Dashboard/Main';

const config = {
    fontFamily: 'Inter',
    borderRadius: 8,
    outlinedFilled: true,
    navType: 'light', // light, dark
    presetColor: 'theme1', // default, theme1, theme2, theme3, theme4, theme5, theme6
    locale: 'en', // 'en' - English
    rtlLayout: false,
    container: false
};

export default config;
