import { useRoutes } from 'react-router-dom';
import { lazy } from 'react';

// project imports
import NavMotion from 'layout/NavMotion';
import Loadable from 'ui-component/Loadable';

// routes
import LoginRoutes from './LoginRoutes';
import MainRoutes from './MainRoutes';

const Error404 = Loadable(lazy(() => import('views/pages/maintenance/Error')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    return useRoutes([
        LoginRoutes,
        MainRoutes,
        {
            path: '*',
            element: (
                <NavMotion>
                    <Error404 />
                </NavMotion>
            )
        }
    ]);
}
