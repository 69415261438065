/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';
import { techmindsAcademyHttpService } from 'api/techmindsacademy.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    interestedUsers: []
};

const slice = createSlice({
    name: 'interestedUsers',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET INTERESTED USERS
        getInterestedUsers(state, action) {
            state.interestedUsers = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

const splitEmail = (email) => {
    const partes = email.split('@');
    const nombre = partes[0];
    const dominio = partes[1];

    const primerosTres = nombre.substring(0, 3);
    const ultimosTres = nombre.substring(nombre.length - 3);

    const dominioPrimerosTres = dominio.substring(0, 3);
    const dominioUltimosTres = nombre.substring(nombre.length - 3);

    const correoAcortado = `${primerosTres}.....${ultimosTres}@${dominioPrimerosTres}...${dominioUltimosTres}`;

    return correoAcortado;
};

const parseData = (data) => {
    const outputArray = [];
    for (const value of data) {
        outputArray.push({
            ...value,
            email: value.email.length > 30 ? splitEmail(value.email) : value.email
        });
    }
    return outputArray;
};

export function getInterestedUsers() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await techmindsAcademyHttpService.getInterestedUsers();
            dispatch(slice.actions.getInterestedUsers(parseData(response)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
