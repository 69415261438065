import getNotificationLink from '../getNotificationLink';
import getTypeNotification from '../getTypeNotification';

const parseNotificationsData = (data) => {
    const outputArray = data.map((value) => ({
        ...value,
        name: value.name ?? 'N/A',
        type: getTypeNotification(value.type),
        quickAccess: getNotificationLink(value.type, value.appName)
    }));

    return outputArray;
};

export default parseNotificationsData;
