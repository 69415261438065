import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'swaggin/payment';

export class EstablishmentPaymentsService extends AxiosRepository {
    async getPendingInvoicePayment() {
        return super.getWithClient(`${RESOURCE}/invoice?status=pending`);
    }

    async getProcessedInvoicePayment() {
        return super.getWithClient(`${RESOURCE}/invoice?status=approved`);
    }

    async getAllEstablishment() {
        return super.getWithClient(`${RESOURCE}/invoice?status=approved`);
    }

    async getProcessedInvoicePaymentByCommerce(commerceId) {
        return super.getWithClient(`${RESOURCE}/invoice?status=approved&commerce=${commerceId}`);
    }

    async processInvoicePayment(body) {
        return super.putWithClient(body, `${RESOURCE}/invoice-process`);
    }
}

export const establishmentPaymentsHttpService = new EstablishmentPaymentsService();
