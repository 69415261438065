import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = '/sendgrid?skip=0&limit=20';

export class UnsuscribeService extends AxiosRepository {
    async getUnsuscribers() {
        return super.getWithClient(`${RESOURCE}`);
    }
}

export const UnsuscribeHttpService = new UnsuscribeService();
