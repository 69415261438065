import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'testers';

export class TestersService extends AxiosRepository {
    async getTesters(app) {
        return super.getWithClient(`${RESOURCE}?application=${app}`);
    }

    async approveTesters(payload) {
        return super.postWithClient(payload, `${RESOURCE}/approve`);
    }
}

export const testersHttpService = new TestersService();
