const TechmindsAcademyIcon = (props) => (
    <svg width={54} height={54} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g filter="url(#a)">
            <circle cx={27} cy={23} r={19} fill="#fff" />
        </g>
        <path d="M16 23.44h4.484V15H16v8.44Z" fill="#000752" />
        <path d="M20.484 15.012v8.435h4.48v8.44h8.969v-8.44h4.484v-8.435H20.484Z" fill="#00E2D1" />
        <path d="M20.493 31.877h4.485v-8.44h-4.485v8.44Z" fill="#000752" />
        <defs>
            <filter id="a" x={0} y={0} width={54} height={54} filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={4} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_202_3" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_202_3" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default TechmindsAcademyIcon;
