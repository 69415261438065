import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import useAuth from 'hooks/useAuth';
import useLocalStorage from 'hooks/useLocalStorage';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const IdleTimeout = (props) => {
    const { timeOutInterval } = props;
    const { isLoggedIn, logout } = useAuth();
    const [isIdle, setIsIdle] = useState(false);
    const location = useLocation();

    const [timerId, setTimerId] = useState();
    const [dialogTimerId, setDialogTimerId] = useState();

    const [lastInteractionTimeStored, setLastInteractionTimeStored] = useLocalStorage('lastInteractionTimeStored', { date: new Date() });
    const events = ['scroll', 'click', 'load', 'keydown', 'mousemove'];
    let timer;
    let dialogTimer;

    const handleOnLogout = async (isAutoLogOut) => {
        if (isAutoLogOut && isLoggedIn) {
            localStorage.setItem('sessionUrl', JSON.stringify(location));
        } else {
            localStorage.removeItem('sessionUrl');
        }
        await logout();
    };

    const startTimer = () => {
        if (timer) {
            clearTimeout(timer);
        }
        timer = setTimeout(() => {
            const lastInteractionTime = new Date(lastInteractionTimeStored.date).getTime();
            const now = new Date().getTime();
            const diff = now - lastInteractionTime;

            if (diff < timeOutInterval) {
                startTimer();
                setIsIdle(false);
            } else {
                setIsIdle(true);
            }
        }, timeOutInterval);
        setTimerId(timer);
    };

    const startDialogTimer = () => {
        dialogTimer = setTimeout(() => {
            handleOnLogout(true);
        }, 12000);
        setDialogTimerId(dialogTimer);
    };

    const eventHandler = () => {
        setLastInteractionTimeStored({ date: new Date() });
        if (timer) {
            startTimer();
        }
    };

    const addEvents = () => {
        events.forEach((eventName) => {
            window.addEventListener(eventName, eventHandler);
        });
        startTimer();
    };

    const removeEvents = () => {
        events.forEach((eventName) => {
            window.removeEventListener(eventName, eventHandler);
        });
    };

    const handleOnClose = async () => {
        clearTimeout(timerId);
        clearTimeout(dialogTimerId);
        setIsIdle(false);
    };

    useEffect(() => {
        if (isLoggedIn) {
            addEvents();
        }
        return () => {
            removeEvents();
            clearTimeout(timerId);
            clearTimeout(dialogTimerId);
            setIsIdle(false);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);

    useEffect(() => {
        if (isIdle) {
            startDialogTimer();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIdle]);

    return (
        <div>
            {isLoggedIn && (
                <Dialog
                    open={isIdle}
                    aria-labelledby="alert-dialog-title-approved"
                    aria-describedby="alert-dialog-description-approved"
                    sx={{ p: 3 }}
                >
                    <DialogTitle>Your session is about to expire</DialogTitle>
                    <DialogActions sx={{ pr: 2.5 }}>
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                                handleOnLogout(false);
                            }}
                        >
                            Logout
                        </Button>
                        <Button variant="contained" size="large" onClick={handleOnClose}>
                            I am here
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

IdleTimeout.propTypes = {
    timeOutInterval: PropTypes.number.isRequired
};

export default IdleTimeout;
