import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'sumlia';

export class BridgeIAService extends AxiosRepository {
    async getPrompts(queryParams) {
        return super.getWithClient(`${RESOURCE}/prompts${queryParams}`);
    }

    async getSharedPrompts() {
        return super.getWithClient(`${RESOURCE}/prompts/shared`);
    }

    async getEngines() {
        return super.getWithClient(`${RESOURCE}/prompts/engines`);
    }

    async getThreads(queryParams) {
        return super.getWithClient(`${RESOURCE}/threads${queryParams}`);
    }

    async getHistoryThread(threadId) {
        return super.getWithClient(`${RESOURCE}/threads/history?threadId=${threadId}`);
    }

    async createPromt(payload) {
        return super.postWithClient(payload, `${RESOURCE}/prompts`);
    }

    async updatePrompt(payload) {
        return super.putWithClient(payload, `${RESOURCE}/prompts`);
    }

    async interactWithPrompt(payload) {
        return super.postWithClient(payload, `${RESOURCE}/prompts/interact`);
    }

    async createMessage(payload) {
        return super.postWithClient(payload, `${RESOURCE}/messages`);
    }

    async sharePrompt(payload) {
        return super.postWithClient(payload, `${RESOURCE}/prompts/share`);
    }
}

export const bridgeIAHttpService = new BridgeIAService();
