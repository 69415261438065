/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Divider, Grid, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Typography } from '@mui/material';

// third-party
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

// project imports
import NotificationAction from './NotificationAction';
import { notificationsHttpService } from 'api/notification.service';

// assets
import BeSoulIcon from 'assets/logos/BeSoulIcon';
import SwagginIcon from 'assets/logos/SwagginIcon';
import TechmindsAcademyIcon from 'assets/logos/TechmindsAcademyIcon';
import EarIcon from 'assets/logos/EarIcon';
import AdminIcon from 'assets/logos/AdminIcon';

const defaultExpandData = [
    {
        id: 'bs',
        title: 'Be Soul',
        icon: BeSoulIcon
    },
    {
        id: 'sw',
        title: 'Swaggin',
        icon: SwagginIcon
    },
    {
        id: 'techmindsAcademy',
        title: 'Techminds Academy',
        icon: TechmindsAcademyIcon
    },
    {
        id: 'ear',
        title: 'EAR',
        icon: EarIcon
    },
    {
        id: 'suVzla',
        title: 'Sitio Uno',
        icon: AdminIcon
    }
];

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ notifications, refresh, handleToggle }) => {
    const theme = useTheme();
    const navigateTo = useNavigate();

    const getIcon = (appName) => {
        const Icon = defaultExpandData.filter((app) => app.title === appName)[0].icon;
        const itemIcon = <Icon stroke={1.5} size="20px" />;

        return itemIcon;
    };

    const handleListItemClick = (notification) => async () => {
        navigateTo(notification.quickAccess);
        handleToggle();
        await notificationsHttpService
            .updateNotifications([
                {
                    id: notification._id
                }
            ])
            .then(() => {
                refresh();
            })
            .catch((err) => console.error('error', err));
    };

    return (
        <List
            sx={{
                width: '100%',
                maxWidth: 330,
                py: 0,
                borderRadius: '10px',
                [theme.breakpoints.down('md')]: {
                    maxWidth: 300
                },
                '& .MuiListItemSecondaryAction-root': {
                    top: 22
                },
                '& .MuiDivider-root': {
                    my: 0
                },
                '& .list-container': {
                    pl: 7
                }
            }}
        >
            {notifications.map((notification, index) => (
                <Fragment key={index}>
                    <ListItemWrapper onClick={handleListItemClick(notification)}>
                        <ListItem alignItems="center">
                            <ListItemAvatar>{getIcon(notification.appName || 'Be Soul')}</ListItemAvatar>
                            <ListItemText
                                primary={
                                    <Typography
                                        fontWeight={700}
                                        fontSize="14px"
                                        color={theme.palette.grey[900]}
                                        sx={{ wordBreak: 'break-word', width: '70%' }}
                                    >
                                        {`New ${notification.type}`}
                                    </Typography>
                                }
                                sx={{
                                    marginTop: 0,
                                    marginBottom: '20px'
                                }}
                            />
                            <ListItemSecondaryAction>
                                <Grid container justifyContent="flex-end">
                                    <Grid item xs={12}>
                                        <Typography fontWeight={400} fontSize="12px" color={theme.palette.grey[500]} gutterBottom>
                                            {moment(notification.createdAt).fromNow() || '2 min ago'}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </ListItemSecondaryAction>
                        </ListItem>
                        <Grid container direction="column" className="list-container">
                            <Grid item xs={12}>
                                <Grid container spacing={1}>
                                    <NotificationAction theme={theme} notification={notification} />
                                    {/* {loadingRequest ? (
                                        <CircularProgress size={28} color="inherit" />
                                    ) : notification.isRead ? (
                                        <Grid item>
                                            <Chip label="Read" sx={chipWarningSX} />
                                        </Grid>
                                    ) : (
                                        <Grid item>
                                            <Chip label="Mark as read" onClick={handleChipClick(notification)} sx={chipAsReadSX} />
                                        </Grid>
                                    )} */}
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItemWrapper>
                    <Divider />
                </Fragment>
            ))}
        </List>
    );
};

NotificationList.propTypes = {
    notifications: PropTypes.array,
    refresh: PropTypes.func,
    handleToggle: PropTypes.func
};

export default NotificationList;
