const SalesIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} fill="none" {...props}>
        <g filter="url(#a)">
            <circle cx={27} cy={23} r={19} fill="#fff" />
            <g fill="#335CD4" clipPath="url(#b)">
                <path
                    d="M27 15c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8Zm1.23 13.33V30H25.9v-1.69c-1.5-.31-2.77-1.28-2.86-2.97h1.71c.09.92.72 1.64 2.32 1.64 1.71 0 2.1-.86 2.1-1.39 0-.73-.39-1.41-2.34-1.87-2.17-.53-3.66-1.42-3.66-3.21 0-1.51 1.22-2.48 2.72-2.81V16h2.34v1.71c1.63.39 2.44 1.63 2.49 2.97h-1.71c-.04-.97-.56-1.64-1.94-1.64-1.31 0-2.1.59-2.1 1.43 0 .73.57 1.22 2.34 1.67 1.77.46 3.66 1.22 3.66 3.42-.01 1.6-1.21 2.48-2.74 2.77Z"
                    opacity={0.3}
                />
                <path d="M27 13c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10Zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8Zm.31-8.86c-1.77-.45-2.34-.94-2.34-1.67 0-.84.79-1.43 2.1-1.43 1.38 0 1.9.66 1.94 1.64h1.71c-.05-1.34-.87-2.57-2.49-2.97V16H25.9v1.69c-1.51.32-2.72 1.3-2.72 2.81 0 1.79 1.49 2.69 3.66 3.21 1.95.46 2.34 1.15 2.34 1.87 0 .53-.39 1.39-2.1 1.39-1.6 0-2.23-.72-2.32-1.64h-1.72c.1 1.7 1.36 2.66 2.86 2.97V30h2.34v-1.67c1.52-.29 2.72-1.16 2.73-2.77-.01-2.2-1.9-2.96-3.66-3.42Z" />
            </g>
        </g>
        <defs>
            <clipPath id="b">
                <path fill="#fff" d="M15 11h24v24H15z" />
            </clipPath>
            <filter id="a" width={54} height={54} x={0} y={0} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={4} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_7241_12332" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_7241_12332" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default SalesIcon;
