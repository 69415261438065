// third-party
import { FormattedMessage } from 'react-intl';

// project-imports
import { SCOPES } from 'permissions';

// assets
import AdminIcon from '../assets/logos/AdminIcon';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';

const icons = {
    AdminIcon,
    ConnectWithoutContactIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const suVzla = {
    id: 'suVzla',
    type: 'group',
    scope: SCOPES.su,
    children: [
        {
            id: 'SUVzla',
            title: <FormattedMessage id="su-vzla" />,
            type: 'collapse',
            url: '/SU',
            icon: icons.AdminIcon,
            level: 0,
            children: [
                {
                    id: 'b2b-services',
                    title: <FormattedMessage id="b2b-services" />,
                    type: 'item',
                    icon: icons.ConnectWithoutContactIcon,
                    url: '/SU/B2B/Services',
                    scope: SCOPES.b2bServices,
                    level: 1
                }
            ]
        }
    ]
};

export default suVzla;
