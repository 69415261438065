/* eslint-disable no-underscore-dangle */
import { useEffect, useRef, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    Button,
    CardActions,
    Chip,
    CircularProgress,
    ClickAwayListener,
    Divider,
    Grid,
    Paper,
    Popper,
    Stack,
    Typography,
    useMediaQuery
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useNavigate } from 'react-router-dom';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import NotificationList from './NotificationList';
import { notificationsHttpService } from 'api/notification.service';
import parseNotificationsData from 'utils/functions/to-parse/notificationsData';

// assets
import { IconBell } from '@tabler/icons';

// ==============================|| NOTIFICATION ||============================== //

const NotificationSection = () => {
    const theme = useTheme();
    const navigateTo = useNavigate();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    /**
     * anchorRef is used on different componets and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef(null);

    const fetchNotifications = async () => {
        const response = await notificationsHttpService.getNotifications(0, 20);
        if (response) {
            setData(parseNotificationsData(response.notifications));
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNotifications();
    }, [open]);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current.focus();
        }
        prevOpen.current = open;
    }, [open]);

    const markAllNotificationsAsRed = async () => {
        const payload = data
            .filter((notification) => !notification.isRead)
            .map((noti) => ({
                id: noti._id
            }));

        setLoading(true);
        await notificationsHttpService
            .updateNotifications(payload)
            .then(() => fetchNotifications())
            .catch((err) => console.error('error', err))
            .finally(() => setLoading(false));
    };

    return (
        <>
            <Box
                sx={{
                    ml: 2,
                    mr: 3,
                    [theme.breakpoints.down('md')]: {
                        mr: 2
                    }
                }}
            >
                <Avatar
                    variant="rounded"
                    sx={{
                        ...theme.typography.commonAvatar,
                        ...theme.typography.mediumAvatar,
                        transition: 'all .2s ease-in-out',
                        width: '48px',
                        height: '48px',
                        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                        color: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.primary.dark,
                        '&[aria-controls="menu-list-grow"],&:hover': {
                            background: theme.palette.mode === 'dark' ? theme.palette.warning.dark : theme.palette.primary.dark,
                            color: theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.primary.light
                        }
                    }}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    color="inherit"
                >
                    <IconBell stroke={1.5} size="20px" />
                </Avatar>
            </Box>

            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? 5 : 0, 20]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Transitions position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
                            <Paper>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                    sx={{ minHeight: '100px', minWidth: '330px' }}
                                >
                                    <Grid container direction="column" spacing={2}>
                                        <Grid item xs={12}>
                                            <Grid container alignItems="center" justifyContent="space-between" sx={{ pt: 2, px: 2 }}>
                                                <Grid item>
                                                    <Stack direction="row" spacing={2}>
                                                        <Typography fontWeight={500} fontSize="16px" color={theme.palette.grey[900]}>
                                                            All Notification
                                                        </Typography>
                                                        <Chip
                                                            size="small"
                                                            label={
                                                                <Typography fontWeight={700} fontSize="14px" color="#FFF">
                                                                    {loading ? 0 : data.length}
                                                                </Typography>
                                                            }
                                                            sx={{
                                                                color: '#FFF',
                                                                bgcolor: theme.palette.success.dark
                                                            }}
                                                        />
                                                    </Stack>
                                                </Grid>
                                                <Grid item>
                                                    <Typography
                                                        fontWeight={400}
                                                        fontSize="12px"
                                                        color={theme.palette.primary.dark}
                                                        onClick={() => markAllNotificationsAsRed()}
                                                        sx={{ cursor: 'pointer' }}
                                                    >
                                                        Mark as all read
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <PerfectScrollbar
                                                style={{
                                                    height: '100%',
                                                    maxHeight: 'calc(100vh - 205px)',
                                                    overflowX: 'hidden'
                                                }}
                                            >
                                                <Divider sx={{ my: 0 }} />
                                                {!loading ? (
                                                    <NotificationList
                                                        notifications={data}
                                                        refresh={fetchNotifications}
                                                        handleToggle={handleToggle}
                                                    />
                                                ) : (
                                                    <div style={{ display: 'flex', justifyContent: 'center', padding: '15px 0' }}>
                                                        <CircularProgress size={28} color="inherit" />
                                                    </div>
                                                )}
                                            </PerfectScrollbar>
                                        </Grid>
                                    </Grid>

                                    <Divider />
                                    <CardActions sx={{ p: 1.25, justifyContent: 'flex-start' }}>
                                        <Button
                                            size="small"
                                            disableElevation
                                            onClick={() => {
                                                navigateTo('Notifications');
                                                setOpen(false);
                                            }}
                                            sx={{ color: theme.palette.primary.dark }}
                                        >
                                            View all notifications...
                                        </Button>
                                    </CardActions>
                                </MainCard>
                            </Paper>
                        </Transitions>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
};

export default NotificationSection;
