import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'users';
const RESOURCE_PAYMENT = 'payment-info';

export class UsersService extends AxiosRepository {
    async getUser(id) {
        return super.get(id, RESOURCE);
    }

    async getProfile() {
        return super.getWithClient(`${RESOURCE}/profile`);
    }

    async getAllUsers() {
        return super.getWithClient(RESOURCE);
    }

    async getAllMerchants(app) {
        return super.getWithClient(`${RESOURCE}/retailer?app=${app}`);
    }

    async createUser(payload) {
        return super.postWithClient(payload, RESOURCE);
    }

    async createMerchant(payload) {
        return super.postWithClient(payload, `${RESOURCE}/retailer`);
    }

    async createPaymentMethod(payload) {
        return super.postWithClient(payload, RESOURCE_PAYMENT);
    }

    async activateAccount(payload) {
        return super.postWithClient({ ...payload, type: 'activate_account' }, `${RESOURCE}/update-password`, false);
    }

    async verifyEmail() {
        return super.postWithClient({}, `${RESOURCE}/verify-email`);
    }

    async resetPassword(payload) {
        return super.postWithClient(payload, `${RESOURCE}/reset-password`, false);
    }

    async changePassword(payload) {
        return super.postWithClient({ ...payload, type: '-' }, `${RESOURCE}/update-password`, false);
    }

    async updateUser(payload, id) {
        return super.putWithClient(payload, `${RESOURCE}/${id}`);
    }

    async updateProfile(payload) {
        return super.putWithClient(payload, `${RESOURCE}/profile`);
    }

    async deleteMerchant(id) {
        return super.delete(id, `${RESOURCE}/retailer`);
    }

    async deletePaymentMethod() {
        return super.deleteWithClient(RESOURCE_PAYMENT);
    }

    async deleteUser(id) {
        return super.delete(id, `${RESOURCE}`);
    }

    async verifyPaymentMethod(payload) {
        return super.postWithClient(payload, `${RESOURCE_PAYMENT}/verify`);
    }

    async addAdditionalInformation(payload, id) {
        return super.putWithClient(payload, `${RESOURCE}/additional-data/${id}`, false);
    }
}

export const usersHttpService = new UsersService();
