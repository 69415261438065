// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, useMediaQuery } from '@mui/material';

// project imports
import LogoSection from '../LogoSection';
import ProfileSection from './ProfileSection';
// import MobileSection from './MobileSection';
import NotificationSection from './NotificationSection';
// import MessageSection from './MessageSection';
import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';
import LogoSU from 'assets/illustration/LogoSU';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { drawerOpen } = useSelector((state) => state.menu);
    const isPhone = useMediaQuery('(max-width: 500px)');

    return (
        <>
            <Grid container spacing={0}>
                <Grid item xs={isPhone ? 2 : 11}>
                    {/* logo & toggler button */}
                    <Box
                        sx={{
                            width: 228,
                            display: 'flex',
                            [theme.breakpoints.down('md')]: {
                                width: 'auto'
                            }
                        }}
                    >
                        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
                            <LogoSection />
                        </Box>
                        <Avatar
                            variant="rounded"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                overflow: 'hidden',
                                transition: 'all .2s ease-in-out',
                                width: '48px',
                                height: '48px',
                                background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light,
                                color: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.dark,
                                '&:hover': {
                                    background: theme.palette.mode === 'dark' ? theme.palette.secondary.main : theme.palette.primary.dark,
                                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.light : theme.palette.primary.light
                                }
                            }}
                            onClick={() => dispatch(openDrawer(!drawerOpen))}
                            color="inherit"
                        >
                            <IconMenu2 stroke={1.5} size="20px" />
                        </Avatar>
                    </Box>
                </Grid>
                {isPhone && (
                    <Grid item xs={8} display="flex" justifyContent="center">
                        <LogoSU />
                    </Grid>
                )}
                <Grid item xs={isPhone ? 2 : 1} display="flex" justifyContent={isPhone ? 'center' : 'flex-end'}>
                    <NotificationSection />
                    <ProfileSection />
                </Grid>
            </Grid>

            {/* header search */}
            {/* <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ flexGrow: 1 }} /> */}

            {/* notification & profile */}
            {/* <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
                <MessageSection />
                <NotificationSection />
            </Box> */}

            {/* mobile header */}
            {/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
                <MobileSection />
            </Box> */}
        </>
    );
};

export default Header;
