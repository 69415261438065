/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { UnsuscribeHttpService } from 'api/unsuscribe.service';

const initialState = {
    error: null,
    loading: false,
    users: []
};

const slice = createSlice({
    name: 'unsuscribers',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET TESTERS
        getUnsuscribers(state, action) {
            state.users = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getUnsuscribers() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await UnsuscribeHttpService.getUnsuscribers();
            dispatch(slice.actions.getUnsuscribers(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
