// action - state management
import { LOADING, LOGIN, LOGOUT, REGISTER, DISABLE } from './actions';

// ==============================|| ACCOUNT REDUCER ||============================== //

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null,
    errorType: null
};

const accountReducer = (state = initialState, action) => {
    switch (action.type) {
        case REGISTER: {
            const { user } = action.payload;
            return {
                ...state,
                user
            };
        }
        case LOGIN: {
            const { user } = action.payload;
            return {
                ...state,
                isLoggedIn: true,
                isInitialized: true,
                user,
                loading: false,
                errorType: null
            };
        }
        case LOGOUT: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                loading: false
            };
        }
        case DISABLE: {
            return {
                ...state,
                isInitialized: true,
                isLoggedIn: false,
                user: null,
                loading: false,
                errorType: 'accountDisabled'
            };
        }
        case LOADING: {
            return {
                ...state,
                loading: true,
                errorType: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

export default accountReducer;
