import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'swaggin';

export class SwagginService extends AxiosRepository {
    async getSubscription() {
        return super.getWithClient(`${RESOURCE}/subscriptions`);
    }

    async getCommerces(skip, limit) {
        return super.getWithClient(`${RESOURCE}/commerce/rating?skip=${skip}&limit=${limit}`);
    }

    async getAllCommerces(skip, limit) {
        return super.getWithClient(`${RESOURCE}/commerce/get?skip=${skip}&limit=${limit}`);
    }

    async getAllCommercesByPromoCode(skip, limit, promoCode) {
        return super.getWithClient(`${RESOURCE}/commerce/get?skip=${skip}&limit=${limit}&promoCode=${promoCode}`);
    }

    async getReviews(commerceId, rate, skip, limit) {
        return super.getWithClient(
            rate !== null
                ? `${RESOURCE}/rate/${commerceId}?stars=${rate}&skip=${skip}&limit=${limit}`
                : `${RESOURCE}/rate/${commerceId}?skip=${skip}&limit=${limit}`
        );
    }

    async getCounterReviews() {
        return super.getWithClient(`${RESOURCE}/rate/stars`);
    }

    async getCommerceOwner(commerceId) {
        return super.getWithClient(`${RESOURCE}/user/commerce/${commerceId}`);
    }

    async getBussinesParams() {
        return super.getWithClient(`${RESOURCE}/payment/config`);
    }

    async updateBussinesParams(payload) {
        return super.putWithClient(payload, `${RESOURCE}/payment/config`);
    }

    async getPacks() {
        return super.getWithClient(`${RESOURCE}/pack/config`);
    }

    async updatePack(payload, packId) {
        return super.putWithClient(payload, `${RESOURCE}/pack/config/${packId}`);
    }

    async getContactUsers() {
        return super.getWithClient(`${RESOURCE}/commerce/contact`);
    }
}

export const swagginHttpService = new SwagginService();
