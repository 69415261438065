// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from 'store';
import { notificationsHttpService } from 'api/notification.service';
import parseNotificationsData from 'utils/functions/to-parse/notificationsData';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    notifications: [],
    totalNotifications: 0
};

const slice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET TESTERS
        getNotifications(state, action) {
            state.notifications = action.payload;
        },

        getTotalNotifications(state, action) {
            state.totalNotifications = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getNotifications(skip, limit) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const { notifications, totalNotifications } = await notificationsHttpService.getNotifications(skip, limit);
            dispatch(slice.actions.getNotifications(parseNotificationsData(notifications)));
            dispatch(slice.actions.getTotalNotifications(totalNotifications));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
