// third-party
import { FormattedMessage } from 'react-intl';

// project-imports
import { SCOPES } from 'permissions';

// assets
import BridgeIAIcon from '../assets/logos/BridgeIAIcon';
import TerminalOutlinedIcon from '@mui/icons-material/TerminalOutlined';

const icons = {
    BridgeIAIcon,
    TerminalOutlinedIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const bridgeAI = {
    id: 'bridge-ai',
    type: 'group',
    scope: SCOPES.bridgeAI,
    children: [
        {
            id: 'BridgeIA',
            title: <FormattedMessage id="bridge-ai" />,
            type: 'collapse',
            url: '/BridgeAI/Prompts',
            icon: icons.BridgeIAIcon,
            level: 0,
            children: [
                {
                    id: 'bridgeIA-promptList',
                    title: <FormattedMessage id="prompts" />,
                    type: 'item',
                    icon: icons.TerminalOutlinedIcon,
                    url: '/BridgeAI/Prompts',
                    scope: SCOPES.prompts,
                    level: 1
                },
                {
                    id: 'bridgeIA-create',
                    title: <FormattedMessage id="create-prompt" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.TerminalOutlinedIcon,
                    url: '/BridgeAI/CreatePrompt',
                    scope: SCOPES.prompts,
                    level: 1
                },
                {
                    id: 'bridgeIA-edit',
                    title: <FormattedMessage id="create-prompt" />,
                    type: 'item',
                    disabled: true,
                    icon: icons.TerminalOutlinedIcon,
                    url: '/BridgeAI/EditPrompt',
                    scope: SCOPES.prompts,
                    level: 1
                }
            ]
        }
    ]
};

export default bridgeAI;
