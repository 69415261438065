import admin from './admin';
import beSoul from './beSoul';
import ear from './ear';
import sales from './sales';
import suVzla from './suVzla';
import swaggin from './swaggin';
import techmindsAcademy from './techmindsAcademy';
import bridgeAI from './bridgeAI';
import biometric from './biometric';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    items: [beSoul, swaggin, techmindsAcademy, suVzla, ear, admin, sales, bridgeAI, biometric]
};

export default menuItems;
