/* eslint-disable class-methods-use-this */
import axios from 'axios';
import RepositoryInterface from '../interface/repository.,interface';
import client from './axiosClient';
import { BACKEND_HOST } from 'config';

export default class AxiosRepository extends RepositoryInterface {
    constructor() {
        super();
        this.URL = BACKEND_HOST;
    }

    async getWithClient(resource, images) {
        try {
            if (images) {
                return await client.get(`${resource}`, {
                    responseType: 'blob'
                });
            }
            return await (
                await client.get(`${resource}`)
            ).data;
        } catch (err) {
            // Gestión de errores
            // Evitamos que los errores de infraestructura pasen a una capa por encima y los manejamos dentro del fetch
            if (err.response) throw new Error(`${err.response.data.statusCode} - ${err.response.data.message}`);
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }

    async getByIdWithClient(id, resource) {
        try {
            return await (
                await client.get(`${resource}/${id}`)
            ).data;
        } catch (err) {
            // Gestión de errores
            // Evitamos que los errores de infraestructura pasen a una capa por encima y los manejamos dentro del fetch
            if (err.response) throw new Error(`${err.response.data.statusCode} - ${err.response.data.message}`);
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }

    async postWithClient(data, resource, authorization) {
        try {
            return await (
                await client.post(`${resource}`, data, { authorization })
            ).data;
        } catch (err) {
            // console.log('err.response', err.response);
            if (err.response.data.message === 'Already exist a user with that email') throw err.response.data;
            if (err.response) throw new Error(`${err.response.data.statusCode} - ${err.response.data.message}`);
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }

    async putWithClient(data, resource, authorization) {
        try {
            return await (
                await client.put(`${resource}`, data, { authorization })
            ).data;
        } catch (err) {
            // console.log('err', err.response);
            if (err.response.data.message === 'Already exist a user with that email') throw err.response.data;
            if (err.response) throw new Error(`${err.response.data.statusCode} - ${err.response.data.message}`);
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }

    async delete(id, resource) {
        try {
            return await (
                await client.delete(`${resource}/${id}`)
            ).data;
        } catch (err) {
            if (err.response.data.statusCode === 409) throw err.response.data.statusCode;
            if (err.response.data.statusCode === 404) throw err.response.data.statusCode;
            if (err.response) throw new Error(`${err.response.data.statusCode} - ${err.response.data.message}`);
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }

    async deleteWithClient(resource) {
        try {
            return await (
                await client.delete(resource)
            ).data;
        } catch (err) {
            if (err.response) throw new Error(`${err.response.data.statusCode} - ${err.response.data.message}`);
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }

    async get(id, resource) {
        try {
            return await (
                await axios.get(`${this.URL}${resource}/${id}`, { headers: this.headers })
            ).data;
        } catch (err) {
            // Gestión de errores
            // Evitamos que los errores de infraestructura pasen a una capa por encima y los manejamos dentro del fetch
            console.error('err', err.request);

            if (err.response) throw new Error(`${err.response.data.statusCode} - ${err.response.data.message}`);
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }

    async post(data, resource) {
        try {
            return await (
                await axios.post(this.URL + resource, data, { headers: this.headers })
            ).data;
        } catch (err) {
            if (err.response) throw new Error(err.response.data.message);
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }

    async getAll(resource) {
        try {
            return await (
                await axios.get(this.URL + resource, { headers: this.headers })
            ).data;
        } catch (err) {
            // Gestión de errores
            // Evitamos que los errores de infraestructura pasen a una capa por encima y los manejamos dentro del fetch
            if (err.response) throw new Error('Not Found or Server Error');
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }

    async put(data, resource) {
        try {
            return await (
                await axios.put(this.URL + resource, data, { headers: this.headers })
            ).data;
        } catch (err) {
            if (err.response) throw new Error(err.response.data.message);
            else if (err.request) throw new Error('Response was not received');
            else throw new Error(`Something else has happened: ${err.message}`);
        }
    }
}
