import PropTypes from 'prop-types';
import { createContext } from 'react';

// project import
import useLocalStorage from 'hooks/useLocalStorage';

// initial state
const initialState = {
    requestValidationPage: 0,
    onChangeRequestValidationPage: () => {}
};

// ==============================|| APP STATES CONTEXT & PROVIDER ||============================== //

const AppStatesContext = createContext(initialState);

function AppStatesProvider({ children }) {
    const [globalStates, setGlobalStates] = useLocalStorage('global-states', {
        requestValidationPage: initialState.requestValidationPage
    });

    const onChangeRequestValidationPage = (requestValidationPage) => {
        setGlobalStates({
            ...globalStates,
            requestValidationPage
        });
    };

    return (
        <AppStatesContext.Provider
            value={{
                ...globalStates,
                onChangeRequestValidationPage
            }}
        >
            {children}
        </AppStatesContext.Provider>
    );
}

AppStatesProvider.propTypes = {
    children: PropTypes.node
};

export { AppStatesProvider, AppStatesContext };
