/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { affiliatePaymentsHttpService } from 'api/affiliates-payments.service';

const initialState = {
    error: null,
    loading: false,
    totalRecordsPending: 0,
    totalRecordsHistory: 0,
    totalPagesPending: 0,
    totalPagesHistory: 0,
    affiliatesPending: [],
    affiliatesHistory: []
};

const slice = createSlice({
    name: 'affiliatesPending',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET TESTERS
        getAffiliatesPending(state, action) {
            state.affiliatesPending = action.payload;
        },

        // GET TESTERS
        getAffiliatesHistory(state, action) {
            state.affiliatesHistory = action.payload;
        },

        setTotalRecordsPending(state, action) {
            state.totalRecordsPending = action.payload;
        },

        setTotalRecordsHistory(state, action) {
            state.totalRecordsHistory = action.payload;
        },

        setTotalPagesPending(state, action) {
            state.totalPagesPending = action.payload;
        },

        setTotalPagesHistory(state, action) {
            state.totalPagesHistory = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

const parseData = (data) => {
    const outputArray = [];
    for (const value of data) {
        outputArray.push({
            ...value,
            invoiceId: value.transactionNumber,
            invoiceAmount: value.amount,
            invoiceDate: value.date,
            typeUser: value.wallet.type_user
        });
    }
    return outputArray;
};

export function getAffiliatesPending(skip, limit, filter, search) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const { records, totalRecords, totalPages } = await affiliatePaymentsHttpService.getPendingPayment(
                skip,
                limit,
                'bs',
                filter,
                search
            );
            dispatch(slice.actions.getAffiliatesPending(parseData(records)));
            dispatch(slice.actions.setTotalRecordsPending(totalRecords));
            dispatch(slice.actions.setTotalPagesPending(totalPages));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getAffiliatesHistory(skip, limit, filter, search) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const { records, totalRecords, totalPages } = await affiliatePaymentsHttpService.getHistoryPayment(
                skip,
                limit,
                'bs',
                filter,
                search
            );
            dispatch(slice.actions.getAffiliatesHistory(parseData(records)));
            dispatch(slice.actions.setTotalRecordsHistory(totalRecords));
            dispatch(slice.actions.setTotalPagesHistory(totalPages));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
