/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
// third-party
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { establishmentPaymentsHttpService } from 'api/establishment-payments.service';

const initialState = {
    pendingInvoices: [],
    processedInvoices: [],
    establishmentList: [],
    establishmentInvoiceHistory: [],
    status: 'loading',
    error: null,
    page: 0,
    totalPage: 0,
    totalPendingInvoices: 0
};

const parseData = (data) => {
    const outputArray = [];
    for (const value of data) {
        outputArray.push({
            ...value,
            invoiceId: value.movementsDetails.transactionNumber,
            invoiceAmount: value.movementsDetails.amount,
            invoiceDate: value.movementsDetails.date
        });
    }
    return outputArray;
};

export const getPendingInvoices = createAsyncThunk('pendingInvoices/getAllInvoices', async () => {
    try {
        const { result } = await establishmentPaymentsHttpService.getPendingInvoicePayment();

        return {
            data: parseData(result.data),
            page: result.page,
            total: result.total,
            totalPages: result.totalPages
        };
    } catch (error) {
        console.error(error);
        return [];
    }
});

export const getProcessedInvoices = createAsyncThunk('processedInvoices/getAllInvoices', async () => {
    try {
        const { result } = await establishmentPaymentsHttpService.getProcessedInvoicePayment();

        return {
            data: parseData(result.data),
            page: result.page,
            total: result.total,
            totalPages: result.totalPages
        };
    } catch (error) {
        console.error(error);
        return [];
    }
});

export const getEstablishmentList = createAsyncThunk('establishment/getEstablishmentData', async () => {
    try {
        const { result } = await establishmentPaymentsHttpService.getAllEstablishment();

        return {
            data: parseData(result.data),
            page: result.page,
            total: result.total,
            totalPages: result.totalPages
        };
    } catch (error) {
        console.error('error', error);
        return [];
    }
});

export const getEstablishmentInvoicesHistory = createAsyncThunk('establishment/getEstablishmentInvoices', async ({ commerceId }) => {
    try {
        const { result } = await establishmentPaymentsHttpService.getProcessedInvoicePaymentByCommerce(commerceId);
        return result.data;
    } catch (error) {
        console.error(error);
        return [];
    }
});

const slice = createSlice({
    name: 'establishmentPayment',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getPendingInvoices.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getPendingInvoices.fulfilled, (state, action) => {
                state.status = 'idle';
                state.pendingInvoices = action.payload.data;
                state.page = action.payload.page;
                state.totalPage = action.payload.totalPage;
                state.totalPendingInvoices = action.payload.total;
            })
            .addCase(getProcessedInvoices.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getProcessedInvoices.fulfilled, (state, action) => {
                state.status = 'idle';
                state.processedInvoices = action.payload.data;
            })
            .addCase(getEstablishmentList.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEstablishmentList.fulfilled, (state, action) => {
                state.status = 'idle';
                state.establishmentList = action.payload.data;
            })
            .addCase(getEstablishmentInvoicesHistory.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(getEstablishmentInvoicesHistory.fulfilled, (state, action) => {
                state.status = 'idle';
                state.establishmentInvoiceHistory = action.payload;
            });
    }
});

export default slice.reducer;
