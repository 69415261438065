/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { beSoulHttpService } from 'api/besoul.service';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: true,
    referrals: {},
    partner: {}
};

const slice = createSlice({
    name: 'referrals',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET TESTERS
        getReferralParams(state, action) {
            state.referrals = action.payload;
        },

        getReferralParamsPartner(state, action) {
            state.partner = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getReferralParams() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const { result } = await beSoulHttpService.getReferralsParams();
            dispatch(slice.actions.getReferralParams(result[0] || {}));
            dispatch(slice.actions.getReferralParamsPartner(result[1] || {}));
            dispatch(slice.actions.isLoading(false));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
