import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'logs';

export class LogsService extends AxiosRepository {
    async getLogs() {
        return super.getWithClient(`${RESOURCE}`);
    }
}

export const logsHttpService = new LogsService();
