import PropTypes from 'prop-types';
import { lazy } from 'react';

// project imports
import AuthGuard from 'utils/route-guard/AuthGuard';
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import PermissionGuard from 'utils/route-guard/PermissionGuard';
import { SCOPES } from 'permissions';

// general routing
const MainDashboard = Loadable(lazy(() => import('ui-component/home/HomeComponent')));
const Notifications = Loadable(lazy(() => import('views/pages/notifications')));

// eden routing
const BesoulDashboardGeneral = Loadable(lazy(() => import('views/besoul/dashboard/general')));
const BeSoulUserHistory = Loadable(lazy(() => import('views/besoul/dashboard/general/History')));
const BesoulDashboardDataStudio = Loadable(lazy(() => import('views/besoul/dashboard/ds')));
const BesoulDashboardMongoChart = Loadable(lazy(() => import('views/besoul/dashboard/mg')));
const ValidationRequest = Loadable(lazy(() => import('views/besoul/validation-request')));
const DeathCertificateDetail = Loadable(lazy(() => import('views/besoul/validation-request/RequestDetail')));
const ValidationHistory = Loadable(lazy(() => import('views/besoul/validation-history')));
const ValidationHistoryDetail = Loadable(lazy(() => import('views/besoul/validation-history/OldRequestDetail')));
// const ReviewWebEdens = Loadable(lazy(() => import('views/besoul/review-web-edens')));
// const ReviewWebEdensDetail = Loadable(lazy(() => import('views/besoul/review-web-edens/WebEdenReview')));
// const WebEdensDetail = Loadable(lazy(() => import('views/besoul/review-web-edens/WebEdenDetail')));
// const ReviewWebEdensHistory = Loadable(lazy(() => import('views/besoul/review-web-edens-history')));
const Subscriptions = Loadable(lazy(() => import('views/besoul/subscriptions')));
const Reports = Loadable(lazy(() => import('views/besoul/reports')));
const ReportDetail = Loadable(lazy(() => import('views/besoul/reports/ReportDetail')));
const ReportsHistory = Loadable(lazy(() => import('views/besoul/reports-history')));
const Testers = Loadable(lazy(() => import('views/besoul/testers')));
const BeSoulCustomerSupport = Loadable(lazy(() => import('views/ear/customer-support')));
const BeSoulReferralProgram = Loadable(lazy(() => import('views/besoul/referral-program')));

// swaggin routing
const SwagginDashboardMongoChart = Loadable(lazy(() => import('views/swaggin/dashboard/mg')));
const SwagginDashboardDataStudio = Loadable(lazy(() => import('views/swaggin/dashboard/ds')));
// const SwagginSubscriptions = Loadable(lazy(() => import('views/swaggin/subscription')));
const SwagginCustomerSupport = Loadable(lazy(() => import('views/ear/customer-support')));
const SwagginBusinessParams = Loadable(lazy(() => import('views/swaggin/business-params')));
const SwagginFormPack = Loadable(lazy(() => import('views/swaggin/business-params/Packs-management/FormPack')));
const SwagginReviews = Loadable(lazy(() => import('views/swaggin/reviews')));
const SwagginReviewsDetails = Loadable(lazy(() => import('views/swaggin/reviews/ReviewsDetails')));
const SwagginCommerces = Loadable(lazy(() => import('views/swaggin/commerces')));
const SwagginCommercesDetail = Loadable(lazy(() => import('views/swaggin/commerces/CommerceDetail')));
const SwagginSpotifyEmails = Loadable(lazy(() => import('views/swaggin/spotify-emails')));
const SwagginSpotifyEmailDetail = Loadable(lazy(() => import('views/swaggin/spotify-emails/EmailDetail')));
const SwagginSpotifyEmailReview = Loadable(lazy(() => import('views/swaggin/spotify-emails/EmailReview')));
const SwagginSpotifyEmailsHistory = Loadable(lazy(() => import('views/swaggin/spotify-emails-history')));
const SwagginContactUsers = Loadable(lazy(() => import('views/swaggin/contact-users')));
const SwagginTransactions = Loadable(lazy(() => import('views/swaggin/transactions')));

// techminds academy routing
const InterestedUsers = Loadable(lazy(() => import('views/techmindsacademy/interested-users')));

// SU Vzla routing
const B2BServices = Loadable(lazy(() => import('views/suvzla/b2b-services')));

// ear routing
const Dashboard = Loadable(lazy(() => import('views/ear/dashboard')));
const Channels = Loadable(lazy(() => import('views/ear/channels')));
const Applications = Loadable(lazy(() => import('views/ear/applications')));
const ActivateApp = Loadable(lazy(() => import('views/ear/applications/configuration')));
const GeneralSupport = Loadable(lazy(() => import('views/ear/customer-support')));

// admin routing
const Users = Loadable(lazy(() => import('views/admin/users-management')));
const UsersViewAll = Loadable(lazy(() => import('views/admin/users-created')));
const UpdateUsers = Loadable(lazy(() => import('views/admin/users-created/UpdateUser')));
const Roles = Loadable(lazy(() => import('views/admin/roles-management')));
const RolesList = Loadable(lazy(() => import('views/admin/roles-management/RolesList')));
const Logs = Loadable(lazy(() => import('views/admin/logs')));
const Unsuscribe = Loadable(lazy(() => import('views/admin/unsuscribe')));
const UnsuscribeGroup = Loadable(lazy(() => import('views/admin/group-unsubscribe')));
const EstablishmentPayments = Loadable(lazy(() => import('views/admin/establishment-payments')));
const InvoiceDetails = Loadable(lazy(() => import('views/admin/establishment-payments/InvoiceDetails')));
const EstablishmentDetails = Loadable(lazy(() => import('views/admin/establishment-payments/EstablishmentDetails')));
const AffiliatePayments = Loadable(lazy(() => import('views/admin/Affiliate-payments')));

// sales routing
const Aplications = Loadable(lazy(() => import('views/sales/management/Aplications')));
const FormPaymentMethod = Loadable(lazy(() => import('views/sales/retailer/Profile/Payments/FormPaymentMethod')));
const RetailersManagement = Loadable(lazy(() => import('views/sales/management')));
const FormMerchant = Loadable(lazy(() => import('views/sales/management/FormMerchant')));
const Supervisor = Loadable(lazy(() => import('views/sales/supervisor')));
const Retailer = Loadable(lazy(() => import('views/sales/retailer')));
const RetailerDashboard = Loadable(lazy(() => import('views/sales/retailer/Dashboard')));
const Profile = Loadable(lazy(() => import('views/sales/retailer/Profile')));
const VerifyPaymentMethod = Loadable(lazy(() => import('views/sales/retailer/Verify')));
const SubscribersHistory = Loadable(lazy(() => import('views/sales/retailer/SubscribersHistory')));
const Subscribers = Loadable(lazy(() => import('views/sales/retailer/Subscribers')));

// sales routing
const Error401 = Loadable(lazy(() => import('views/pages/maintenance/Error401')));

// bridge ai routing
const Prompts = Loadable(lazy(() => import('views/bridgeAI/Prompts')));
const CreatePrompt = Loadable(lazy(() => import('views/bridgeAI/CreatePrompt')));
const EditPrompt = Loadable(lazy(() => import('views/bridgeAI/EditPrompt')));

// Biometric module
const Biometric = Loadable(lazy(() => import('views/biometric/Biometric')));
const BiometricTransactionDetail = Loadable(lazy(() => import('views/biometric/TransactionDetail')));

// guard wrapper
const PermissionGuardWrapped = ({ children, scopeName }) => (
    <PermissionGuard scopes={[scopeName]} isNavBar={false}>
        {children}
    </PermissionGuard>
);

PermissionGuardWrapped.propTypes = {
    children: PropTypes.node,
    scopeName: PropTypes.string
};

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: (
        <AuthGuard>
            <MainLayout />
        </AuthGuard>
    ),
    children: [
        {
            path: 'Dashboard/Main',
            element: <MainDashboard />
        },
        {
            path: 'Notifications',
            element: <Notifications />
        },
        {
            path: 'BeSoul/Dashboard/General',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.dashboard}.${SCOPES.general}`}>
                    <BesoulDashboardGeneral />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/Dashboard/General/History',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.dashboard}.${SCOPES.general}`}>
                    <BeSoulUserHistory />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/Dashboard/MongoChart',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.dashboard}.${SCOPES.app}`}>
                    <BesoulDashboardMongoChart />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/Dashboard/DataStudio',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.dashboard}.${SCOPES.technical}`}>
                    <BesoulDashboardDataStudio />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/ValidationRequest',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES['death-certificate']}`}>
                    <ValidationRequest />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/ValidationRequest/Detail/:id',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES['death-certificate']}`}>
                    <DeathCertificateDetail />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/ValidationHistory',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES['death-certificate']}`}>
                    <ValidationHistory />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/ValidationHistory/Detail/:id',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES['death-certificate']}`}>
                    <ValidationHistoryDetail />
                </PermissionGuardWrapped>
            )
        },
        // {
        //     path: 'BeSoul/WebEdens',
        //     element: (
        //         <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.edensWeb}`}>
        //             <ReviewWebEdens />
        //         </PermissionGuardWrapped>
        //     )
        // },
        // {
        //     path: 'BeSoul/WebEdens/Review/:id',
        //     element: (
        //         <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.edensWeb}`}>
        //             <ReviewWebEdensDetail />
        //         </PermissionGuardWrapped>
        //     )
        // },
        // {
        //     path: 'BeSoul/WebEdens/Detail/:id',
        //     element: (
        //         <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.edensWeb}`}>
        //             <WebEdensDetail />
        //         </PermissionGuardWrapped>
        //     )
        // },
        // {
        //     path: 'BeSoul/WebEdensHistory',
        //     element: (
        //         <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.edensWeb}`}>
        //             <ReviewWebEdensHistory />
        //         </PermissionGuardWrapped>
        //     )
        // },
        {
            path: 'BeSoul/Subscriptions',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.subscriptions}`}>
                    <Subscriptions />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/Reports',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.reports}`}>
                    <Reports />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/Reports/Detail/:id',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.reports}`}>
                    <ReportDetail />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/ReportsHistory',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.reports}`}>
                    <ReportsHistory />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/Testers',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.testers}`}>
                    <Testers />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/CustomerSupport',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.customerSupport}`}>
                    <BeSoulCustomerSupport appName="Be Soul" />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'BeSoul/ReferralProgram',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.customerSupport}`}>
                    <BeSoulReferralProgram />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/Dashboard/MongoChart',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.dashboard}.${SCOPES.app}`}>
                    <SwagginDashboardMongoChart />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/Dashboard/DataStudio',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.dashboard}.${SCOPES.technical}`}>
                    <SwagginDashboardDataStudio />
                </PermissionGuardWrapped>
            )
        },
        // {
        //     path: 'Swaggin/Subscriptions',
        //     element: (
        //         <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.subscriptions}`}>
        //             <SwagginSubscriptions />
        //         </PermissionGuardWrapped>
        //     )
        // },
        {
            path: 'Swaggin/CustomerSupport',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.customerSupport}`}>
                    <SwagginCustomerSupport appName="Swaggin" />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/BusinessParams',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.businessParameters}`}>
                    <SwagginBusinessParams />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/BusinessParams/New',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.businessParameters}`}>
                    <SwagginFormPack />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/BusinessParams/UpdatePack',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.businessParameters}`}>
                    <SwagginFormPack />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/Reviews',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.reviews}`}>
                    <SwagginReviews />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/Reviews/Details',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.reviews}`}>
                    <SwagginReviewsDetails />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/Transactions',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.customerSupport}`}>
                    <SwagginTransactions />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/Commerces',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.commerces}`}>
                    <SwagginCommerces />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/Commerces/Detail/:id',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.commerces}`}>
                    <SwagginCommercesDetail />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/SpotifyEmails',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.spotifyEmails}`}>
                    <SwagginSpotifyEmails />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/SpotifyEmails/Review/:id',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.spotifyEmails}`}>
                    <SwagginSpotifyEmailReview />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/SpotifyEmails/Detail/:id',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.spotifyEmails}`}>
                    <SwagginSpotifyEmailDetail />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/SpotifyEmails/History',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.spotifyEmails}`}>
                    <SwagginSpotifyEmailsHistory />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Swaggin/ContactUsers',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sw}.${SCOPES.contactUsers}`}>
                    <SwagginContactUsers />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'TechmindsAcademy/UsersInterested',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.tma}.${SCOPES.interestedUsers}`}>
                    <InterestedUsers />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'SU/B2B/Services',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.su}.${SCOPES.b2bServices}`}>
                    <B2BServices />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Ear/Dashboard',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.ear}.${SCOPES.dashboard}`}>
                    <Dashboard />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Ear/Configurations/Channels',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.ear}.${SCOPES.configuration}.${SCOPES.channels}`}>
                    <Channels />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Ear/Configurations/Applications',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.ear}.${SCOPES.configuration}.${SCOPES.applications}`}>
                    <Applications />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Ear/Configurations/Applications/Activate',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.ear}.${SCOPES.configuration}.${SCOPES.applications}`}>
                    <ActivateApp />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Ear/CustomerSupport/General',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.ear}.${SCOPES.dashboard}`}>
                    <GeneralSupport appName="General" />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Users',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES.account}.${SCOPES.usersManagement}`}>
                    <Users />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Users/ViewAll',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES.account}.${SCOPES.users}`}>
                    <UsersViewAll />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Users/UpdateUser',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES.account}.${SCOPES.users}`}>
                    <UpdateUsers />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Roles',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES.account}.${SCOPES.rolesManagement}`}>
                    <RolesList />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Roles/Create',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES.account}.${SCOPES.rolesManagement}`}>
                    <Roles />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Logs',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES.logs}`}>
                    <Logs />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/SendgridGlobalEmails',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES.sendgrid}.${SCOPES.global}`}>
                    <Unsuscribe />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/SendgridGroupEmails',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES.sendgrid}.${SCOPES.group}`}>
                    <UnsuscribeGroup />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Establishment-payments',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES['establishment-payment']}`}>
                    <EstablishmentPayments />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Establishment-payments/Pending-invoices/Process',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES['establishment-payment']}`}>
                    <InvoiceDetails />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Establishment-payments/Processed-invoices',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES['establishment-payment']}`}>
                    <InvoiceDetails process />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Establishment-payments/Establishment-details/:id',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES['establishment-payment']}`}>
                    <EstablishmentDetails />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Affiliate-payments',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES.sendgrid}.${SCOPES.group}`}>
                    <AffiliatePayments />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Affiliate-payments/Invoice-details',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES['establishment-payment']}`}>
                    <InvoiceDetails type="affiliate" />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Admin/Affiliate-payments/Invoice-details/Process',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.admin}.${SCOPES['establishment-payment']}`}>
                    <InvoiceDetails type="affiliate" process />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Aplications',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailersManagement}`}>
                    <Aplications />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/RetailersManagement',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailersManagement}`}>
                    <RetailersManagement />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/RetailersManagement/New',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailersManagement}`}>
                    <FormMerchant />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/RetailersManagement/Edit',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailersManagement}`}>
                    <FormMerchant />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Merchant',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.merchant}`}>
                    <Supervisor />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Retailers/Dashboard',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailer}`}>
                    <RetailerDashboard />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Retailers/:app',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailer}`}>
                    <Retailer />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Retailers/Detail',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailer}`}>
                    <Profile />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Retailers/History',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailer}`}>
                    <SubscribersHistory />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Retailers/:app/Subscribers',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailer}`}>
                    <Subscribers />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Retailers/PaymentMethod/New',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailer}`}>
                    <FormPaymentMethod />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Retailers/PaymentMethod/Edit',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailer}`}>
                    <FormPaymentMethod />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Sales/Retailers/PaymentMethod/Verify',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.sales}.${SCOPES.retailer}`}>
                    <VerifyPaymentMethod />
                </PermissionGuardWrapped>
            )
        },
        {
            path: '/BridgeAI/Prompts',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bridgeAI}.${SCOPES.prompts}`}>
                    <Prompts />
                </PermissionGuardWrapped>
            )
        },
        {
            path: '/BridgeAI/CreatePrompt',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bridgeAI}.${SCOPES.prompts}`}>
                    <CreatePrompt />
                </PermissionGuardWrapped>
            )
        },
        {
            path: '/BridgeAI/EditPrompt',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bridgeAI}.${SCOPES.prompts}`}>
                    <EditPrompt />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Biometric',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.biometric}.${SCOPES.transactions}`}>
                    <Biometric />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Biometric/Detail',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.biometric}.${SCOPES.transactions}`}>
                    <BiometricTransactionDetail />
                </PermissionGuardWrapped>
            )
        },
        {
            path: 'Pages/Error-401',
            element: (
                <PermissionGuardWrapped scopeName={`${SCOPES.bs}.${SCOPES.dashboard}.${SCOPES.app}`}>
                    <Error401 />
                </PermissionGuardWrapped>
            )
        }
    ]
};

export default MainRoutes;
