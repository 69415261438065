export default class RepositoryInterface {
    constructor() {
        if (this.constructor === RepositoryInterface) {
            throw new Error("Abstract classes can't be instantiated.");
        }
    }

    get() {
        throw new this.Error('Method "getAll()" must be implemented.');
    }

    post() {
        throw new this.Error('Method "post()" must be implemented.');
    }

    getAll() {
        throw new this.Error('Method "getAll()" must be implemented.');
    }

    put() {
        throw new this.Error('Method "put()" must be implemented.');
    }
}
