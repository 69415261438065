// third-party
import { createSlice } from '@reduxjs/toolkit';
import { earHttpService } from 'api/ear.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    tickets: [],
    loading: false
};

const slice = createSlice({
    name: 'tickets',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // GET TICKETS
        getTickets(state, action) {
            state.tickets = action.payload;
        },

        // GET TICKETS
        getGeneralTickets(state, action) {
            state.tickets = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getTickets(ownerId) {
    return async () => {
        try {
            const response = await earHttpService.getTicket(ownerId);
            dispatch(slice.actions.getTickets(response.result));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function getGeneralTickets(ownerIds) {
    return async () => {
        try {
            let finalResult = [];
            const promesas = ownerIds.map((ownerId) => earHttpService.getTicket(ownerId));

            // Ejecutar todas las promesas simultáneamente
            Promise.all(promesas)
                .then((resultados) => {
                    // Manejar los resultados de las promesas
                    resultados.forEach((resultado) => {
                        const arrayResult = resultado.result;
                        finalResult = finalResult.concat(arrayResult);
                    });
                    dispatch(slice.actions.getTickets(finalResult));
                })
                .catch((error) => {
                    // Manejar errores
                    console.error(`Error en la llamada a la API:`, error);
                });
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
