/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';
import { biometricHttpService } from 'api/biometric.service';
// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    biometricUsers: [],
    biometricDocuments: [],
    biometricFaces: [],
    skip: 0,
    page: 0,
    amount: 0,
    totalPages: 0
};

const slice = createSlice({
    name: 'biometricUsers',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET BIOMETRIC USERS
        getBiometricUsers(state, action) {
            state.biometricUsers = action.payload;
        },

        // GET INTERESTED USERS
        getBiometricDocuments(state, action) {
            state.biometricDocuments = [...state.biometricDocuments, action];
        },

        // GET INTERESTED USERS
        getBiometricFaces(state, action) {
            state.biometricFaces = [...state.biometricFaces, action];
        },

        setSkip(state, action) {
            state.skip = action.payload;
        },
        setPage(state, action) {
            state.totalPages = action.payload;
        },
        setAmount(state, action) {
            state.amount = action.payload;
        }
    }
});

const parseData = (data) => {
    const outputArray = [];
    for (const value of data) {
        outputArray.push({
            fullName: value.sender.fullName,
            ...value
        });
    }

    return outputArray;
};
export function getBiometricUsers(skip, limit, filter, search) {
    return async () => {
        try {
            let records;
            let pageInfo;
            dispatch(slice.actions.isLoading(true));
            if (search) {
                const response = await biometricHttpService.getBiometricUsers(skip + 1, limit, filter, search);
                records = response.records;
                pageInfo = response.pageInfo;
            } else {
                const response = await biometricHttpService.getBiometricUsers(skip + 1, limit);
                records = response.records;
                pageInfo = response.pageInfo;
            }
            const { totalRecords } = pageInfo;
            const page = Math.ceil(totalRecords % limit);
            dispatch(slice.actions.getBiometricUsers(parseData(records)));
            dispatch(slice.actions.setPage(page));
            dispatch(slice.actions.setAmount(totalRecords));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getBiometricDocuments(documentId) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const { src } = await biometricHttpService.getBiometricDocuments(documentId);
            dispatch(slice.actions.getBiometricDocuments(src));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getBiometricFaces(faceId) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const { records } = await biometricHttpService.getBiometricFaces(faceId);
            dispatch(slice.actions.getBiometricFaces(records));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

// Reducer
export default slice.reducer;
