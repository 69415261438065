/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { beSoulHttpService } from 'api/besoul.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    validations: [],
    history: []
};

const slice = createSlice({
    name: 'validations',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // IS LOADING
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET VALIDATIONS REQUEST
        getValidationsSuccess(state, action) {
            state.validations = action.payload;
        },

        // GET VALIDATIONS HISTORY
        getHistorySuccess(state, action) {
            state.history = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

const parseValidationRequestData = (data) => {
    const outputArray = [];
    try {
        for (const value of data) {
            outputArray.push({
                ...value,
                id: value.id,
                name: value.user.fullName,
                sender: value.user.email,
                legacyId: value.legacy ? value.legacy.legacyId : 'N/A',
                userDeceased: value.legacy ? value.legacy.user.fullName : 'N/A',
                // birthDate: value.legacy ? value.legacy.birthDate : 'N/A',
                // deathDate: value.legacy ? value.legacy.deathDate : 'N/A',
                // placeOfDeath: value.legacy ? value.legacy.placeOfDeath : 'N/A',
                date: value.createdAt,
                status: value.status,
                type: value.type,
                file: value.files.length > 0 ? value.files[0].path : undefined,
                funeralHome: value.mortuaryInfo ? { ...value.mortuaryInfo } : undefined
            });
        }
    } catch (error) {
        console.error('error', error);
    }
    return outputArray;
};

const parseRequestHistoryData = (data) => {
    const outputArray = [];
    for (const value of data) {
        outputArray.push({
            id: value._id,
            status: value.status,
            sender: value.userId.fullName,
            userDeceased: value.referenceId.userId.fullName,
            date: value.updatedAt,
            type: value.type,
            // detail data
            file: value.files.length > 0 ? value.files[0].url : undefined,
            fileId: value.files.length > 0 ? value.files[0]._id : undefined,
            senderEmail: value.userId.email,
            placeOfDeath: value.referenceId.placeOfDeath,
            birthDate: value.referenceId.birthDate,
            deathDate: value.referenceId.deathDate,
            funeralHome: value.mortuaryInfo ? { ...value.mortuaryInfo } : undefined
        });
    }

    return outputArray;
};

export function getValidationRequest(page, limit) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const {
                result: { records, pageInfo }
            } = await beSoulHttpService.getPendingRequest(page, limit);
            dispatch(slice.actions.getValidationsSuccess(parseValidationRequestData(records)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getRequestHistory() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await beSoulHttpService.getRequestHistory();
            dispatch(slice.actions.getHistorySuccess(parseRequestHistoryData(response.data)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
