/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';
import { testersHttpService } from 'api/testers.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    testers: [],
    history: []
};

const slice = createSlice({
    name: 'testers',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET TESTERS
        getTesters(state, action) {
            state.testers = action.payload;
        },

        // GET EDENS HISTORY
        getTestersHistory(state, action) {
            state.history = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

// const parseData = (data) => {
//     const outputArray = [];
//     for (const value of data) {
//         outputArray.push({
//             name: value.name,
//             email: value.email,
//             commerceName: value.commerce[0].name,
//             createdAt: value.commerce[0].createdAt,
//             spotifyEmail: value.commerce[0].spotifyAuth.spotifyEmail
//         });
//     }
//     return outputArray;
// };

export function getTesters(app) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await testersHttpService.getTesters(app);
            dispatch(slice.actions.getTesters(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getTestersHistory(app) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            //  TO DO: get edens landing history
            const response = await testersHttpService.getTesters(app);
            dispatch(slice.actions.getTestersHistory(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
