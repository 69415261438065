/* eslint-disable no-restricted-syntax */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { rolesHttpService } from 'api/roles.service';
import { usersHttpService } from 'api/user.service';
import decryptData from 'utils/functions/decryptData';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: true,
    usersS1: [],
    usersS2: [],
    users: [],
    roles: [],
    followers: [],
    friendRequests: [],
    friends: [],
    gallery: [],
    posts: [],
    detailCards: [],
    simpleCards: [],
    profileCards: [],
    roleInfo: []
};

const slice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // IS LOADING
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET USERS STYLE 1
        getUsersListStyle1Success(state, action) {
            state.usersS1 = action.payload;
        },

        // GET USERS STYLE 2
        getUsersListStyle2Success(state, action) {
            state.usersS2 = action.payload;
        },

        // GET USERS STYLE 1
        getUsers(state, action) {
            state.users = action.payload;
        },

        // GET USERS STYLE 1
        getRoles(state, action) {
            state.roles = action.payload;
        },

        // GET FOLLOWERS
        getFollowersSuccess(state, action) {
            state.followers = action.payload;
        },

        // FILTER FOLLOWERS
        filterFollowersSuccess(state, action) {
            state.followers = action.payload;
        },

        // GET FRIEND REQUESTS
        getFriendRequestsSuccess(state, action) {
            state.friendRequests = action.payload;
        },

        // FILTER FRIEND REQUESTS
        filterFriendRequestsSuccess(state, action) {
            state.friendRequests = action.payload;
        },

        // GET FRIENDS
        getFriendsSuccess(state, action) {
            state.friends = action.payload;
        },

        // FILTER FRIENDS
        filterFriendsSuccess(state, action) {
            state.friends = action.payload;
        },

        // GET GALLERY
        getGallerySuccess(state, action) {
            state.gallery = action.payload;
        },

        // GET POSTS
        getPostsSuccess(state, action) {
            state.posts = action.payload;
        },

        // EDIT COMMENT
        editCommentSuccess(state, action) {
            state.posts = action.payload;
        },

        // ADD COMMENT
        addCommentSuccess(state, action) {
            state.posts = action.payload;
        },

        // ADD REPLY
        addReplySuccess(state, action) {
            state.posts = action.payload;
        },

        // LIKE POST
        likePostSuccess(state, action) {
            state.posts = action.payload;
        },

        // LIKE COMMENT
        likeCommentSuccess(state, action) {
            state.posts = action.payload;
        },

        // LIKE REPLY
        likeReplySuccess(state, action) {
            state.posts = action.payload;
        },

        // GET DETAIL CARDS
        getDetailCardsSuccess(state, action) {
            state.detailCards = action.payload;
        },

        // FILTER DETAIL CARDS
        filterDetailCardsSuccess(state, action) {
            state.detailCards = action.payload;
        },

        // GET SIMPLE CARDS
        getSimpleCardsSuccess(state, action) {
            state.simpleCards = action.payload;
        },

        // FILTER SIMPLE CARDS
        filterSimpleCardsSuccess(state, action) {
            state.simpleCards = action.payload;
        },

        // GET PROFILE CARDS
        getProfileCardsSuccess(state, action) {
            state.profileCards = action.payload;
        },

        // GET PROFILE CARDS
        getRoleInformation(state, action) {
            state.roleInfo = action.payload;
        },

        // FILTER PROFILE CARDS
        filterProfileCardsSuccess(state, action) {
            state.profileCards = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

const parseData = (data) => {
    const outputArray = [];
    try {
        for (const value of data) {
            outputArray.push({
                ...value,
                roleName: value.role.name
            });
        }
    } catch (error) {
        console.erorr('error', error);
    }
    return outputArray;
};

export function getUsers() {
    return async () => {
        Promise.all([usersHttpService.getAllUsers(), rolesHttpService.getRoles()])
            .then((response) => {
                dispatch(slice.actions.getUsers(parseData(JSON.parse(decryptData(response[0].users)))));
                dispatch(slice.actions.getRoles(JSON.parse(decryptData(response[1].roles))));
            })
            .catch((error) => dispatch(slice.actions.hasError(error)))
            .finally(() => dispatch(slice.actions.isLoading(false)));
    };
}

export function getRoles() {
    return async () => {
        try {
            const { roles } = await rolesHttpService.getRoles();

            dispatch(slice.actions.getRoles(JSON.parse(decryptData(roles))));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getRoleInfo(id) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));

            const { infoRole } = await rolesHttpService.getRoleInformation(id);

            dispatch(slice.actions.getRoleInformation(JSON.parse(decryptData(infoRole))));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
