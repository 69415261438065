import { Link } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// styles
const ErrorWrapper = styled('div')({
    maxWidth: '70%',
    margin: '0 auto',
    textAlign: 'center'
});

const ErrorCard = styled(Card)({
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});

// ==============================|| ERROR PAGE ||============================== //

const Error401 = () => {
    const theme = useTheme();

    return (
        <ErrorCard>
            <CardContent>
                <Grid container justifyContent="center" spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Typography fontWeight={800} fontSize={24} color="#D0D7F5" align="center">
                            Error
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography fontWeight={800} fontSize={128} color={theme.palette.primary.main} align="center" mb="47px">
                            401
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorWrapper>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12}>
                                    <Typography fontWeight={600} fontSize={35} color="#D0D7F5" mb="20px">
                                        Sorry, the page you are looking for has not been found.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography fontWeight={500} fontSize={35} color="#D0D7F5" mb="74px">
                                        {`The page you are looking for doesn't seem to exist or the link is not enabled, if you think there
                                    has been an error please report it.`}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <AnimateButton>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            component={Link}
                                            to={DASHBOARD_PATH}
                                            sx={{ width: '372px', height: '104px', borderRadius: 34.5 }}
                                        >
                                            Back to home page
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                            </Grid>
                        </ErrorWrapper>
                    </Grid>
                </Grid>
            </CardContent>
        </ErrorCard>
    );
};
export default Error401;
