// third-party
import { FormattedMessage } from 'react-intl';

// project-imports
import { SCOPES } from 'permissions';

// assets
import AdminIcon from '../assets/logos/AdminIcon';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import { IconTool, IconMail, IconClipboardList } from '@tabler/icons';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import StorefrontIcon from '@mui/icons-material/Storefront';
import AffiliatesIcon from '../assets/icons/AffiliatesIcon';
import PaymentsIcon from '../assets/icons/PaymentsIcon';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';

// constant
const icons = {
    AdminIcon,
    IconMail,
    PersonAddOutlinedIcon,
    GroupsOutlinedIcon,
    RecentActorsIcon,
    IconTool,
    FormatListNumberedIcon,
    IconClipboardList,
    StorefrontIcon,
    AffiliatesIcon,
    PaymentsIcon,
    ManageAccountsIcon,
    AdminPanelSettingsIcon
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const admin = {
    id: 'admin',
    type: 'group',
    scope: SCOPES.admin,
    children: [
        {
            id: 'Admin',
            title: <FormattedMessage id="admin" />,
            type: 'collapse',
            url: '/admin',
            icon: icons.AdminIcon,
            level: 0,
            children: [
                {
                    id: 'account-setting',
                    title: <FormattedMessage id="account-setting" />,
                    type: 'collapse',
                    icon: icons.IconTool,
                    scope: SCOPES.account,
                    level: 1,
                    children: [
                        {
                            id: 'admin-users',
                            title: <FormattedMessage id="user-management" />,
                            type: 'item',
                            icon: icons.GroupsOutlinedIcon,
                            url: '/Admin/Users',
                            scope: SCOPES.usersManagement,
                            level: 2
                        },
                        {
                            id: 'admin-users-create',
                            title: <FormattedMessage id="user-created" />,
                            type: 'item',
                            icon: icons.PersonAddOutlinedIcon,
                            url: '/Admin/Users/ViewAll',
                            scope: SCOPES.users,
                            level: 2
                        }
                    ]
                },
                {
                    id: 'roles-setting',
                    title: <FormattedMessage id="roles-setting" />,
                    type: 'collapse',
                    icon: icons.AdminPanelSettingsIcon,
                    scope: SCOPES.account,
                    level: 1,
                    children: [
                        {
                            id: 'admin-roles-created',
                            title: <FormattedMessage id="roles-management" />,
                            type: 'item',
                            icon: icons.ManageAccountsIcon,
                            url: '/Admin/Roles/Create',
                            scope: SCOPES.rolesManagement,
                            level: 2
                        },
                        {
                            id: 'admin-roles',
                            title: <FormattedMessage id="roles-created" />,
                            type: 'item',
                            icon: icons.RecentActorsIcon,
                            url: '/Admin/Roles',
                            scope: SCOPES.rolesManagement,
                            level: 2
                        }
                    ]
                },
                {
                    id: 'logs',
                    title: <FormattedMessage id="logs" />,
                    type: 'item',
                    icon: icons.FormatListNumberedIcon,
                    url: '/Admin/Logs',
                    scope: SCOPES.logs,
                    level: 1
                },
                // TODO: manejar el paginado en proxima version
                {
                    id: 'sendgrid-emails',
                    title: <FormattedMessage id="sendgrid-emails" />,
                    type: 'collapse',
                    icon: icons.IconMail,
                    scope: SCOPES.sendgrid,
                    level: 1,
                    children: [
                        {
                            id: 'admin-global-unsuscribes',
                            title: <FormattedMessage id="sendgrid-unsuscribes" />,
                            type: 'item',
                            icon: icons.FormatListNumberedIcon,
                            url: '/Admin/SendgridGlobalEmails',
                            scope: SCOPES.global,
                            level: 2
                        },
                        {
                            id: 'admin-group-unsuscribes',
                            title: <FormattedMessage id="sendgrid-group-unsuscribes" />,
                            disabled: false,
                            type: 'item',
                            icon: icons.IconClipboardList,
                            url: '/Admin/SendgridGroupEmails',
                            scope: SCOPES.group,
                            level: 2
                        }
                    ]
                },
                {
                    id: 'establishment-payments',
                    title: <FormattedMessage id="establishment-payments" />,
                    type: 'collapse',
                    icon: icons.StorefrontIcon,
                    // scope: SCOPES['establishment-payment'],
                    scope: SCOPES.sendgrid,
                    level: 1,
                    children: [
                        {
                            id: 'establishment-payments-sw',
                            title: <FormattedMessage id="establishment-payments-sw" />,
                            type: 'item',
                            icon: icons.PaymentsIcon,
                            url: '/Admin/Establishment-payments',
                            scope: SCOPES.global,
                            level: 2
                        },
                        {
                            id: 'establishment-payments-bs',
                            title: <FormattedMessage id="establishment-payments-bs" />,
                            type: 'item',
                            icon: icons.AffiliatesIcon,
                            url: '/Admin/Affiliate-payments',
                            scope: SCOPES.group,
                            level: 2
                        }
                    ]
                }
            ]
        }
    ]
};

export default admin;
