import useAuth from './useAuth';

const useGetUserAccess = () => {
    const { user } = useAuth();

    if (!user) return '';

    return { role: user.role, permissions: user.permissions };
};

export default useGetUserAccess;
