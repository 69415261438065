/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import { logsHttpService } from 'api/logs.service';

const initialState = {
    error: null,
    loading: false,
    logs: []
};

const slice = createSlice({
    name: 'logs',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET TESTERS
        getLogs(state, action) {
            state.logs = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getLogs() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await logsHttpService.getLogs();
            dispatch(slice.actions.getLogs(response.logs));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
