import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'supf/payments';

export class AffiliatePaymentsService extends AxiosRepository {
    async getPendingPayment(skip, limit, app, filter, search) {
        return super.getWithClient(`${RESOURCE}/pending?skip=${skip}&limit=${limit}&app=${app}&${filter}=${search}`);
    }

    async getHistoryPayment(skip, limit, app, filter, search) {
        return super.getWithClient(`${RESOURCE}/history?skip=${skip}&limit=${limit}&app=${app}&${filter}=${search}`);
    }

    async processPayment(body) {
        return super.putWithClient(body, `${RESOURCE}/process`);
    }
}

export const affiliatePaymentsHttpService = new AffiliatePaymentsService();
