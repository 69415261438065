const LogoSU = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={56} height={30} fill="none" {...props}>
        <path
            fill="#002E81"
            d="M19.284 13.477c-1.37-.72-3.484-1.416-6.343-2.086-2.859-.67-4.658-1.315-5.399-1.935-.58-.488-.872-1.074-.873-1.756a2.105 2.105 0 0 1 .933-1.8c.967-.699 2.304-1.048 4.01-1.048 1.653 0 2.895.327 3.723.98.827.652 1.365 1.72 1.613 3.205l5.879-.257c-.09-2.662-1.06-4.791-2.908-6.388C18.07.796 15.32-.002 11.67 0 9.436 0 7.527.336 5.945 1.009c-1.581.673-2.793 1.652-3.633 2.937a7.438 7.438 0 0 0-1.26 4.143c0 2.293.893 4.238 2.68 5.834 1.27 1.133 3.48 2.09 6.63 2.868 2.448.605 4.016 1.027 4.704 1.265 1.006.356 1.71.774 2.114 1.255.409.493.624 1.117.606 1.756 0 1.067-.48 2-1.44 2.798s-2.386 1.195-4.28 1.191c-1.785 0-3.204-.45-4.256-1.35-1.053-.901-1.75-2.303-2.094-4.207L0 20.053c.384 3.242 1.562 5.71 3.533 7.406C5.505 29.155 8.33 30.002 12.01 30c2.53 0 4.642-.353 6.333-1.058 1.69-.705 3.002-1.783 3.932-3.233a8.514 8.514 0 0 0 1.39-4.668c0-1.831-.388-3.37-1.161-4.617a8.171 8.171 0 0 0-3.219-2.947Zm34.872 12.611a1.828 1.828 0 0 0-1.673 1.132 1.811 1.811 0 0 0 .405 1.975 1.826 1.826 0 0 0 3.108-1.289 1.82 1.82 0 0 0-1.138-1.682 1.838 1.838 0 0 0-.702-.136Zm-.012 3.249a1.451 1.451 0 0 1-1.023-.426 1.44 1.44 0 0 1 1.032-2.46c.807 0 1.389.66 1.382 1.465-.007.805-.595 1.426-1.391 1.42Z"
        />
        <path
            fill="#002E81"
            d="M54.563 27.952v-.023a.462.462 0 0 0 .378-.414.495.495 0 0 0-.173-.398 1.132 1.132 0 0 0-.63-.135 3.698 3.698 0 0 0-.696.052l-.013 1.806h.42v-.727h.199c.233 0 .343.09.374.288.023.153.069.302.136.443h.454a1.368 1.368 0 0 1-.136-.453c-.038-.239-.137-.371-.313-.439Zm-.496-.135h-.2v-.519c.077-.015.154-.022.232-.02.271 0 .387.114.385.278.003.187-.186.262-.417.261Zm-8.953-11.271c0 2.294-.086 3.934-.258 4.922a4.02 4.02 0 0 1-1.528 2.5c-.847.681-2.104 1.022-3.77 1.022-1.64 0-2.934-.36-3.88-1.08a4.68 4.68 0 0 1-1.794-2.857c-.147-.739-.22-2.354-.219-4.844V.513h-5.87l-2.939 2.929v2.211h2.938v10.32c0 3.242.192 5.693.575 7.354a7.968 7.968 0 0 0 1.648 3.212c.834 1.021 1.968 1.852 3.402 2.491 1.433.64 3.54.96 6.32.96 2.303 0 4.168-.294 5.596-.882 1.428-.587 2.568-1.374 3.42-2.362.853-.99 1.441-2.203 1.762-3.638.32-1.434.48-3.893.476-7.374V.514h-5.879v16.032Z"
        />
    </svg>
);
export default LogoSU;
