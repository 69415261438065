const RetailersManageicon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={24} height={23} fill="none" {...props}>
        <path
            fill="#335CD4"
            d="M24 4.537v4.145c-.05-.02-.102-.034-.148-.06-1.517-.874-3.36-.748-4.757.318a.561.561 0 0 1-.317.102c-1.25.007-2.5.001-3.75.007-.612.002-1.007.4-1.01.995-.001.595.393.994 1.004 1.003.476.007.953.002 1.43.002h.462c-.096.103-.147.16-.2.214-1.602 1.61-3.202 3.222-4.807 4.828-.727.727-1.274 1.568-1.58 2.551-.145.467-.229.954-.342 1.442-.04.002-.095.006-.149.006H5.008c-.18 0-.36-.002-.538-.023C1.88 19.759.004 17.64.002 15.016c-.003-3.313-.003-6.625.002-9.938 0-.297.017-.598.071-.89C.54 1.686 2.544.014 5.078.006 6.195 0 7.312.004 8.43.005c3.562 0 7.124-.016 10.686.01 2.21.015 4.114 1.533 4.7 3.675.076.279.123.565.183.847ZM8.003 15.091c.114-.013.205-.025.297-.034 1.268-.136 2.095-.837 2.503-2.029.566-1.651-.272-3.258-1.9-3.665-.385-.097-.782-.144-1.175-.211-.677-.115-1.355-.223-2.031-.34-.406-.07-.647-.313-.68-.668a.997.997 0 0 1 .998-1.11c.718-.002 1.437.004 2.155-.003.427-.003.745.167.981.528.323.494.924.622 1.393.316.466-.305.592-.908.278-1.409-.52-.826-1.265-1.303-2.234-1.419-.19-.023-.382-.03-.587-.047 0-.324.003-.63 0-.935-.004-.282-.065-.549-.282-.748-.334-.304-.719-.364-1.132-.2-.402.16-.582.478-.588.903-.004.328 0 .656 0 .987-.1.01-.178.017-.255.026-1.352.139-2.309.976-2.63 2.302-.367 1.512.39 2.923 1.824 3.35.424.127.87.178 1.308.254.684.119 1.37.224 2.054.344.435.076.672.34.684.739a.992.992 0 0 1-1.002 1.039c-.718.001-1.437-.004-2.155.002-.428.005-.745-.166-.981-.526-.325-.495-.923-.624-1.392-.316-.467.306-.59.905-.279 1.408.447.72 1.085 1.184 1.911 1.364.293.063.597.076.894.112.006.009.013.015.013.022.003.314-.023.631.016.94.029.224.106.463.232.645.236.34.685.446 1.072.315.415-.14.674-.496.687-.966.01-.32.002-.64.002-.969Zm9.005-8.058c.648 0 1.295.003 1.943 0 .617-.003 1.015-.385 1.024-.979.01-.612-.375-1.027-.995-1.03-1.319-.011-2.637-.005-3.955-.003-.3 0-.575.086-.769.332-.271.345-.314.73-.138 1.127.166.375.477.548.878.551.67.005 1.342.002 2.012.002Z"
        />
        <path
            fill="#335CD4"
            d="M12 22.104c.012-.666-.011-1.335.046-1.998.084-.962.494-1.798 1.17-2.481 2.278-2.305 4.564-4.602 6.857-6.893.638-.638 1.422-.843 2.286-.582.87.264 1.4.875 1.598 1.771.008.036.029.07.043.104v.66c-.127.489-.313.945-.678 1.31-2.291 2.3-4.578 4.603-6.872 6.897a4.071 4.071 0 0 1-1.963 1.083c-.188.045-.376.086-.564.13H12Z"
        />
    </svg>
);

export default RetailersManageicon;
