/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';
import { swagginHttpService } from 'api/swaggin.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    commerces: [],
    totalReviews: 0,
    page: 0,
    totalCommerces: 0,
    commerceOwner: {},
    skip: 0,
    allCount: 0,
    allCommerces: [],
    retailerCommerces: []
};

const slice = createSlice({
    name: 'commerces',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET COMMERCES
        getCommerces(state, action) {
            state.commerces = action.payload;
        },

        // GET COMMERCE OWNER
        getCommerceOwner(state, action) {
            state.commerceOwner = action.payload;
        },

        setTotalreviews(state, action) {
            state.totalReviews = action.payload;
        },

        setPage(state, action) {
            state.totalPages = action.payload;
        },

        setTotalCommerces(state, action) {
            state.totalCommerces = action.payload;
        },

        getAllCommerces(state, action) {
            state.allCommerces = action.payload;
        },

        getRetailerCommerces(state, action) {
            state.retailerCommerces = action.payload;
        },

        setAllCount(state, action) {
            state.allCount = action.payload;
        },

        setSkip(state, action) {
            state.skip = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

export function getAllCommerces(skip, limit) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await swagginHttpService.getAllCommerces(skip + 1, limit);
            const {
                result: { commerces, totalCommerces, page }
            } = response;

            dispatch(slice.actions.getAllCommerces(commerces));
            dispatch(slice.actions.setAllCount(totalCommerces));
            dispatch(slice.actions.setSkip(page));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getCommerces(skip, limit) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await swagginHttpService.getCommerces(skip, limit);
            const {
                result: { commerces, totalCommerces, page }
            } = response;
            let total = 0;

            commerces.forEach((commerce) => {
                total += commerce.ratingCount;
            });
            dispatch(slice.actions.getCommerces(commerces));
            dispatch(slice.actions.setTotalreviews(total));
            dispatch(slice.actions.setTotalCommerces(totalCommerces));
            dispatch(slice.actions.setPage(page));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getCommerceOwner(commerceId) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await swagginHttpService.getCommerceOwner(commerceId);
            dispatch(slice.actions.getCommerceOwner(response));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getCommercesByRetailer(promoCode) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await swagginHttpService.getAllCommercesByPromoCode(0, 25, promoCode);
            const {
                result: { commerces }
            } = response;

            dispatch(slice.actions.getRetailerCommerces(commerces));
        } catch (error) {
            dispatch(slice.actions.getRetailerCommerces([]));
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function refreshCommerceOwner() {
    return async () => dispatch(slice.actions.getCommerceOwner({}));
}
