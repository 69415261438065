const BeSoulIcon = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={54} height={54} fill="none" {...props}>
        <g filter="url(#a)">
            <circle cx={27} cy={23} r={19} fill="#fff" />
        </g>
        <path
            fill="#F8CC9D"
            d="M35.433 30.832a11.457 11.457 0 0 1-7.629 3.652l.678-1.395c.194-.4.286-.82.286-1.23a2.826 2.826 0 0 0-2.386-2.79 2.826 2.826 0 0 1-2.019-4.177l1.885-3.346.752-1.337 1.524 1.921c.002.002.003.003.003.005l6.906 8.697Z"
        />
        <path fill="#fff" d="m27 20.21-.751 1.336a1.928 1.928 0 0 0-.773.586l1.525-1.923Z" />
        <path
            fill="#97CADB"
            d="M38.5 23.014c0 3.018-1.165 5.767-3.067 7.818l-6.905-8.699-.003-.004a1.95 1.95 0 0 0-3.05.003l-.002.003-6.903 8.698a11.458 11.458 0 0 1-3.04-8.65c.405-5.757 5.076-10.343 10.84-10.65 6.638-.357 12.13 4.922 12.13 11.48Z"
        />
        <defs>
            <filter id="a" width={54} height={54} x={0} y={0} colorInterpolationFilters="sRGB" filterUnits="userSpaceOnUse">
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={4} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.18 0" />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_4641_65655" />
                <feBlend in="SourceGraphic" in2="effect1_dropShadow_4641_65655" result="shape" />
            </filter>
        </defs>
    </svg>
);

export default BeSoulIcon;
