/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
// third-party
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// project imports
import { bridgeIAHttpService } from 'api/bridgeIA.service';
import { dispatch } from 'store';

const initialState = {
    error: null,
    loading: false,
    prompt: {},
    prompts: [],
    engines: [],
    threadId: '',
    historyThread: []
};

export const getPrompts = createAsyncThunk('bridgeAI/getPrompts', async ({ selectedFilterOption, searchValue, byUserId }) => {
    const allQueries = ['?'];
    if (byUserId) {
        allQueries.push(`userId=${byUserId}&`);
    }
    if (selectedFilterOption && searchValue) {
        allQueries.push(`${selectedFilterOption}=${searchValue}&`);
    }
    const queryParams = allQueries.join('');
    const { result } = await bridgeIAHttpService.getPrompts(queryParams);

    return result;
});

const slice = createSlice({
    name: 'bridgeAI',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET PROMPTS
        getPrompts(state, action) {
            state.prompts = action.payload;
        },

        // SET PROMPTS
        setPrompts(state, action) {
            state.prompts = action.payload;
        },

        // GET SHARED PROMPTS
        getSharedPrompts(state, action) {
            state.prompts = action.payload;
        },

        // SET PROMPT
        setPrompt(state, action) {
            state.prompt = action.payload;
        },

        // GET ENGINES
        getEngines(state, action) {
            state.engines = action.payload;
        },

        // GET THREADID
        getThread(state, action) {
            state.threadId = action.payload;
        },

        // SET THREADID
        setThread(state, action) {
            state.threadId = action.payload;
        },

        // GET HISTORY THREAD
        getHistoryThread(state, action) {
            state.historyThread = action.payload;
        },

        // GET HISTORY THREAD
        addToHistoryThread(state, action) {
            state.historyThread = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPrompts.pending, (state) => {
                state.loading = true;
            })
            .addCase(getPrompts.fulfilled, (state, action) => {
                state.prompts = action.payload;
                state.loading = false;
            });
    }
});

// Reducer
export default slice.reducer;

export function getPromptsTradicional(selectedFilterOption, searchValue, byUserId) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const allQueries = ['?'];

            if (byUserId) {
                allQueries.push(`userId=${byUserId}&`);
            }
            if (selectedFilterOption && searchValue) {
                allQueries.push(`${selectedFilterOption}=${searchValue}&`);
            }

            const queryParams = allQueries.join('');
            const { result } = await bridgeIAHttpService.getPrompts(queryParams);

            dispatch(slice.actions.getPrompts(result));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getSharedPrompts() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const { result } = await bridgeIAHttpService.getSharedPrompts();

            dispatch(slice.actions.getSharedPrompts(result));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getEngines() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await bridgeIAHttpService.getEngines();

            dispatch(slice.actions.getEngines(response.result));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getThread(promptId) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const allQueries = ['?'];

            if (promptId) {
                allQueries.push(`promptId=${promptId}&`);
            }

            const queryParams = allQueries.join('');
            const { result } = await bridgeIAHttpService.getThreads(queryParams);

            dispatch(slice.actions.getThread(result[0].threadId));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function getHistoryThread(threadId) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const { result } = await bridgeIAHttpService.getHistoryThread(threadId);
            dispatch(slice.actions.getHistoryThread(result.slice(0, -2).reverse()));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}

export function setPrompts(prompts) {
    return async () => {
        try {
            dispatch(slice.actions.setPrompts(prompts));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setPrompt(prompt) {
    return async () => {
        try {
            dispatch(slice.actions.setPrompt(prompt));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function setThreadId(threadId) {
    return async () => {
        try {
            dispatch(slice.actions.setThread(threadId));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}

export function addToHistoryThread(thread) {
    return async () => {
        try {
            dispatch(slice.actions.addToHistoryThread(thread));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
