import AxiosRepository from 'http/axios/axios.repository';

const RESOURCE = 'techmindsacademy';

export class TechmindsAcademyService extends AxiosRepository {
    async getInterestedUsers() {
        return super.getWithClient(`${RESOURCE}/courses/interested`);
    }
}

export const techmindsAcademyHttpService = new TechmindsAcademyService();
