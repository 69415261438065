/* eslint-disable no-restricted-syntax */
/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
// third-party
import { createSlice } from '@reduxjs/toolkit';
import { beSoulHttpService } from 'api/besoul.service';

// project imports
import { dispatch } from '../index';

// ----------------------------------------------------------------------

const initialState = {
    error: null,
    loading: false,
    reports: [],
    history: [],
    totalReportsPending: 0,
    totalReportsHistory: 0
};

const slice = createSlice({
    name: 'reports',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        // HAS ERROR
        isLoading(state, action) {
            state.loading = action.payload;
        },

        // GET REPORTS
        getReports(state, action) {
            state.reports = action.payload;
        },

        // GET REPORTS HISTORY
        getHistoryReports(state, action) {
            state.history = action.payload;
        },

        setTotalReportspending(state, action) {
            state.totalReportsPending = action.payload;
        },

        setTotalReportsHistory(state, action) {
            state.totalReportsHistory = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;

const getReportName = (report) => {
    if (report.memorial) {
        return report.memorial.name;
    }

    if (report.post) {
        return report.post.title ? report.post.title : 'N/A';
    }

    if (report.publicationInformation) {
        return report.publicationInformation ? report.publicationInformation.name : 'N/A';
    }

    if (report.commentInformation) {
        return report.commentInformation ? report.commentInformation.name : 'N/A';
    }

    return 'N/A';
};

const getReportType = (source, section) => {
    switch (source) {
        case 'Memorial':
            if (section === 'Publications') return 'Memorial dedication';
            return 'Memorial';
        case 'Profile':
            return 'Professional profile';
        case 'Publications':
            return 'Publications';
        case 'Badges':
            return 'Badges';
        case 'Podcast':
            return 'Podcast';
        case 'Post':
            if (section === 'Publications') return 'Comment on post';
            return 'Post';
        case 'Comment':
            if (section === 'Badges') return 'Comment on badge';
            return 'Comment';
        default:
            return 'N/A';
    }
};

const parseData = (data) => {
    const outputArray = [];
    try {
        for (const value of data) {
            outputArray.push({
                ...value,
                report: getReportName(value.reports[0]),
                type: getReportType(value.source, value.section),
                reports: value.reports ? value.reports : 'N/A',
                reportsCount: value.count,
                lastReportDate: value.reports[value.reports.length - 1].createdAt
            });
        }
    } catch (error) {
        console.error('error', error);
    }

    return outputArray;
};

export function getReports(skip, limit) {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const {
                result: { records, pageInfo }
            } = await beSoulHttpService.getPendingReports(skip, limit);

            dispatch(slice.actions.getReports(parseData(records)));
            dispatch(slice.actions.setTotalReportspending(pageInfo.totalRecords));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
export function getReportsHistory() {
    return async () => {
        try {
            dispatch(slice.actions.isLoading(true));
            const response = await beSoulHttpService.getHistoryReports();
            dispatch(slice.actions.getHistoryReports(parseData(response.data)));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        } finally {
            dispatch(slice.actions.isLoading(false));
        }
    };
}
